import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BarChart from "./BarChart";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";
import { CHART_TYPES } from '../../constant';
import '../../App.css';

export default class Chart extends Component {
	render() {
		const { chartType, className, options, data } = this.props;
		let Component = null;
		if (chartType === CHART_TYPES.AREA) {
			Component = AreaChart;
		} else if (chartType === CHART_TYPES.BAR) {
			Component = BarChart;
		} else if (chartType === CHART_TYPES.PIE) {
			Component = PieChart;
		}
		return <div className={`chartWrapper ${className}`}>
			<Component className={className} options={options} data={data} {...this.props} />
		</div>;
	}
}

Chart.propTypes = {
	chartType: PropTypes.string,
	series: PropTypes.array,
	options: PropTypes.object,
	data: PropTypes.object,
  className: PropTypes.string,
};

Chart.defaultProps = {
  className: '',
};
