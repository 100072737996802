import {chartColors} from "../../../../constant";

const pointStyleAllocated = `text-align:center;font-size:20px;color:${chartColors[0]}`;
const pointStyleDisbursed = `text-align:center;font-size:20px;color:${chartColors[1]}`;

export const areaOptions = {
  showDataLabels: false,
  // width: 500,
  color: chartColors[0],
  titleColor: chartColors[0],
  tooltip: {
    shared: true,
    useHTML: true,
    headerFormat: '<span style="text-align:center;font-size:18px">{series.name}</span><br>',
    pointFormat: `<div style="${pointStyleAllocated}"><b>&#8377; {point.y} cr</b></div>`,
  },
};

export const disbursedOptions = {
  ...areaOptions,
  color: chartColors[1],
  tooltip: {
    ...areaOptions.tooltip,
    pointFormat: `<div style="${pointStyleDisbursed}"><b>&#8377; {point.y} cr</b></div>`,
  }
};
