import React from 'react';
import get from "lodash/get";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import {CHART_TYPES, chartColors, tableColors} from "../../../constant";
import Chart from "../../charts/Chart";

export default ({data}) => {
  const colorPoints = tableColors[0];
  const color = `rgb(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]})`;
  const background = `rgba(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]}, .1)`;

  const dataItem = get(data, 'trends[0]', []);

  return <div className="graph-sec">
    <div className="graph-box" style={{position: 'relative'}}>
      <div style={{padding: '0 20px 0 20px'}}>
        <h3>
          <small>{dataItem.trend_as_on}</small>
          {dataItem.trend_title}
        </h3>
        <AnimatedCounter style={{color: chartColors[0]}} element="p" count={dataItem.trend_count}/>
        {
          dataItem.trend_icon ? (
            <div className="trend-image-container">
              <img src={dataItem.trend_icon} alt={dataItem.trend_title}/>
            </div>
          ) : null
        }
      </div>
      <div className='tabular-view-upper-chart left-section-table'>
        <ul>
          {
            get(dataItem, 'trend_data').map(({year, count}) => {
              return (<li className="left-section-table-row" style={{background}}>
                <div className="left-section-table-year">
                  <div style={{borderColor: color}} className="left-section-table-row-radio"/>
                  <div style={{color}}>
                    {year}
                  </div>
                </div>
                <AnimatedCounter element="div" count={`$${count}mn`}/>
              </li>);
            })
          }
        </ul>
      </div>
    </div>
  </div>;
};
