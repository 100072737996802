import axios from 'axios';
let baseURL = 'https://meadashboard.gov.in/backend/';
if (process.env && process.env.REACT_APP_ENV === 'qa')
baseURL = 'http://meadashboard.gov.in/backend/';

const axiosInstance = axios.create({
    baseURL,
    timeout: 50000,
  });


const getClustersReq = text => ({
    type: 'GET_CLUSTER_REQ',
    isFetching: true
})

const getClustersSuccess = data => ({
    type: 'GET_CLUSTER_SUCCESS',
    data,
    isFetching: false
})

const getClustersFailure = err => ({
    type: 'GET_CLUSTER_FAILURE',
    errMessage: err,
    isFetching: false
})

const getBannerReq = text => ({
    type: 'GET_BANNER_REQ',
    isFetching: true
})

const getBannerSuccess = data => ({
    type: 'GET_BANNER_SUCCESS',
    data,
    isFetching: false
})

const getBannerFailure = err => ({
    type: 'GET_BANNER_FAILURE',
    errMessage: err,
    isFetching: false
})

const getClusterIndicatorsReq = text => ({
    type: 'GET_CLUSTER_INDICATOR_REQ',
    isFetching: true
})

const getClusterIndicatorsSuccess = data => ({
    type: 'GET_CLUSTER_INDICATOR_SUCCESS',
    data,
    isFetching: false
})

const getClusterIndicatorsFailure = err => ({
    type: 'GET_CLUSTER_INDICATOR_FAILURE',
    errMessage: err,
    isFetching: false
})

const getClusterIndicatorsDetailReq = text => ({
    type: 'GET_CLUSTER_INDICATOR_REQ',
    isFetching: true
})

const getClusterIndicatorsDetailSuccess = data => ({
    type: 'GET_CLUSTER_INDICATOR_SUCCESS',
    data,
    isFetching: false
})

const getClusterIndicatorsDetailFailure = err => ({
    type: 'GET_CLUSTER_INDICATOR_FAILURE',
    errMessage: err,
    isFetching: false
})


const getInauguratedReq = text => ({
    type: 'GET_INAUGURATED_REQ',
    isFetching: true
})

const getInauguratedSuccess = data => ({
    type: 'GET_INAUGURATED_SUCCESS',
    data,
    isFetching: false
})

const getInauguratedFailure = err => ({
    type: 'GET_INAUGURATED_FAILURE',
    errMessage: err,
    isFetching: false
})

const getInauguratedCountryReq = text => ({
	type: 'GET_INAUGURATEDCOUNTRY_REQ',
	isFetching: true
})

const getInauguratedCountrySuccess = data => ({
	type: 'GET_INAUGURATEDCOUNTRY_SUCCESS',
	data,
	isFetching: false
})

const getInauguratedCountryFailure = err => ({
	type: 'GET_INAUGURATEDCOUNTRY_FAILURE',
	errMessage: err,
	isFetching: false
})

const getCountByCountryReq = text => ({
	type: 'GET_COUNTBYCOUNTRY_REQ',
	isFetching: true
})

const getCountByCountrySuccess = data => ({
	type: 'GET_COUNTBYCOUNTRY_SUCCESS',
	data,
	isFetching: false
})

const getCountByCountryFailure = err => ({
	type: 'GET_COUNTBYCOUNTRY_FAILURE',
	errMessage: err,
	isFetching: false
})

export const getClusterIndicatorsDetailById = id => {
    return (dispatch) => {
		dispatch(getClusterIndicatorsDetailReq());
		return axiosInstance.get(id === '89' ? 
		`api/v1/cluster-indicator-detail/89?social=dynamic` : `api/v1/cluster-indicator-detail/${id}`)
			.then((res) => {
				dispatch(getClusterIndicatorsDetailSuccess(res.data));
				return Promise.resolve(res.data);
			}).catch((err) => {
				dispatch(getClusterIndicatorsDetailFailure(err.message));
				return Promise.reject(err);
			});
	};
}

export const getClusters = () => {
    return (dispatch) => {
		dispatch(getClustersReq());
		return axiosInstance.get('api/v1/cluster-menu')
			.then((res) => {
				dispatch(getClustersSuccess(res.data));
				return Promise.resolve(res.data);
			}).catch((err) => {
				dispatch(getClustersFailure(err.message));
				return Promise.reject(err);
			});
	};
}

export const getHomePageBanner = () => {
    return (dispatch) => {
		dispatch(getBannerReq());
		return axiosInstance.get('api/v1/homepage-banners')
			.then((res) => {
				dispatch(getBannerSuccess(res.data));
				return Promise.resolve(res.data);
			}).catch((err) => {
				dispatch(getBannerFailure(err.message));
				return Promise.reject(err);
			});
	};
}

export const getClusterIndicatorsById = id => {
    return (dispatch) => {
		dispatch(getClusterIndicatorsReq());
		return axiosInstance.get(`api/v1/cluster-indicator/${id}`)
			.then((res) => {
				dispatch(getClusterIndicatorsSuccess(res.data));
				return Promise.resolve(res.data);
			}).catch((err) => {
        dispatch(getClusterIndicatorsFailure(err.message));
				return Promise.reject(err);
			});
	};
}

export const getMapApi = () => {
    return () => {
		return axiosInstance.get('api/v1/map')
			.then((res) => {
				return Promise.resolve(res.data);
			}).catch((err) => {
				return Promise.reject(err);
			});
	};
}

export const getGlobalMapHeader = () => {
    return () => {
		return axiosInstance.get('api/v1/homepage-menu')
			.then((res) => {
				return Promise.resolve(res.data);
			}).catch((err) => {
				return Promise.reject(err);
			});
	};
}

export const getIndiaMapFootPrintData = () => {
    return () => {
		return axiosInstance.get('api/v1/india-footprint-menu')
			.then((res) => {
				return Promise.resolve(res.data);
			}).catch((err) => {
				return Promise.reject(err);
			});
	};
}

export const getMapData = (url) => {
    return () => {
		return axiosInstance.get(url)
			.then((res) => {
				return Promise.resolve(res.data);
			}).catch((err) => {
				return Promise.reject(err);
			});
	};
}


export const MEAActions = {
    GET_CLUSTER_REQ: 'GET_CLUSTER_REQ',
    GET_CLUSTER_SUCCESS: 'GET_CLUSTER_SUCCESS',
    GET_CLUSTER_FAILURE: 'GET_CLUSTER_FAILURE',
}

export const getInaugurated = () => {
    return (dispatch) => {
		dispatch(getInauguratedReq());
		return axiosInstance.get('api/v1/inaugurated')
			.then((res) => {
				dispatch(getInauguratedSuccess(res.data));
				return Promise.resolve(res.data);
			}).catch((err) => {
				dispatch(getInauguratedFailure(err.message));
				return Promise.reject(err);
			});
	};
}

export const getInauguratedCountry = (country) => {
	return (dispatch) => {
	dispatch(getInauguratedCountryReq());
	return axiosInstance.get(`api/v1/inaugurated/${country}`)
		.then((res) => {
			dispatch(getInauguratedCountrySuccess(res.data));
			return Promise.resolve(res.data);
		}).catch((err) => {
			dispatch(getInauguratedCountryFailure(err.message));
			return Promise.reject(err);
		});
};
}

export const getCountByCountry = () => {
	return (dispatch) => {
	dispatch(getCountByCountryReq());
	return axiosInstance.get('api/v1/inaugurated/countbycountry')
		.then((res) => {
			dispatch(getCountByCountrySuccess(res.data));
			return Promise.resolve(res.data);
		}).catch((err) => {
			dispatch(getCountByCountryFailure(err.message));
			return Promise.reject(err);
		});
};
}