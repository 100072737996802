import { EOPNOTSUPP } from "constants";

var data = [
  ["Andaman & Nicobar Island", 0],
  ["Gujarat", 1],
  ["Bihar", 2],
  ["Lakshadweep", 3],
  ["Madhya Pradesh", 4],
  ["Karnataka", 5],
  ["Uttar Pradesh", 6],
  ["West Bengal", 7],
  ["Assam", 8],
  ["Puducherry", 9],
  ["Andhra Pradesh", 10],
  ["Chandigarh", 11],
  ["Punjab", 12],
  ["Chhattisgarh", 13],
  ["Maharashtra", 14],
  ["Dadara & Nagar Havelli", 15],
  ["Daman & Diu", 16],
  ["Haryana", 17],
  ["Himachal Pradesh", 18],
  ["Goa", 19],
  ["Rajasthan", 20],
  ["Meghalaya", 21],
  ["Arunanchal Pradesh", 22],
  ["Nagaland", 23],
  ["Kerala", 24],
  ["Tamil Nadu", 25],
  ["Telangana", 26],
  ["Tripura", 27],
  ["Mizoram", 28],
  ["Delhi", 29],
  ["Sikkim", 30],
  ["Jammu & Kashmir", 31],
  ["Manipur", 32],
  ["Uttarakhand", 33],
  ["Odisha", 34],
  ["Jharkhand", 35],
  ["andaman & nicobar island", 36],
  ["gujarat", 37],
  ["bihar", 38],
  ["lakshadweep", 39],
  ["madhya pradesh", 40],
  ["karnataka", 41],
  ["uttar pradesh", 42],
  ["west bengal", 43],
  ["assam", 44],
  ["puducherry", 45],
  ["andhra pradesh", 46],
  ["chandigarh", 47],
  ["punjab", 48],
  ["chhattisgarh", 49],
  ["maharashtra", 50],
  ["dadara & nagar havelli", 51],
  ["daman & diu", 52],
  ["haryana", 53],
  ["himachal pradesh", 54],
  ["goa", 55],
  ["rajasthan", 56],
  ["meghalaya", 57],
  ["arunanchal pradesh", 58],
  ["nagaland", 59],
  ["kerala", 60],
  ["tamil nadu", 61],
  ["telangana", 62],
  ["tripura", 63],
  ["mizoram", 64],
  ["delhi", 65],
  ["sikkim", 66],
  ["jammu & kashmir", 67],
  ["manipur", 68],
  ["uttarakhand", 69],
  ["odisha", 70],
  ["jharkhand", 71]
];

const dataJson = data.map(item => {
  return {
    "hc-key": item[0],
    "value": item[1],
  }
})

export default dataJson;