import React, {Component} from 'react';
import IndiaMap from './India/indiaMap';
import {connect} from 'react-redux';
import {getIndiaMapFootPrintData, getMapData} from '../../Actions';
import './landingPageIndiaMap.css';
import CountInfo from "./CountInfo";

class LandingPageIndiaMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [],
      states: [],
      selectedState: '',
      dateRange: '',
      totalCount: '',
      selectedTab: null,
    };
  }

  componentDidMount() {
    this.props
      .getIndiaMapFootPrintData()
      .then((data) => {
        let headerData = data.map((data, id) => ({
          ...data,
          selected: false
        }));
        headerData[0].selected = true;
        let getMapsdata = [];
        headerData.length &&
        headerData.forEach((mapUrlData) => {
          getMapsdata.push(this.props.getMapData(mapUrlData.link_url));
        });
        Promise.all(getMapsdata)
          .then((values) => {
            headerData = headerData.map((data, index) => ({
              ...data,
              mapData: values[index]
            }));
            this.setState({headerData, selectedTab: headerData[0], states: headerData[0].mapData.data});
          })
          .catch((err) => console.log('getAllMapsData landingPageIndiaMap Err ', err));
        return headerData;
      })
      .catch((err) => console.log('getIndiaFootPrintHeader Err ', err));
  }

  handleTabClick(selectedTab) {
    this.setState({states: selectedTab.mapData.data, selectedTab});
  }

  getSelectedStateOrCountry = (state, totalCount, dateRange) => {
    this.setState({
      selectedState: state,
      dateRange,
      totalCount
    })
  };

  render() {
    const {headerData, selectedTab, selectedState, states} = this.state;
    return (
      <section className="destination-outer">
        <div className="india-footprint-wrapper">
          <div className="destination-sec india-footprint" style={{width: '100%'}}>
          <span className="india-text">
                    <img src="/images/india-text.png" alt="" />
                </span>
            <div className="chart-outer remove-float">
              <h3 className={`animated ${this.props.activeFold === true ? `fadeInDown` : ``}`}>MEA’s Indian Footprint</h3>

              <ul className={`animated india-map-tabs ${this.props.activeFold === true ? `fadeInDown` : ``}`}>
                {
                  headerData && headerData.length && headerData.map((item) => <li
                    key={item.link_name}
                    onClick={() => this.handleTabClick(item)}
                    className={`tab ${this.state.selectedTab.id === item.id ? 'active' : ''}`}>
                    <a href="javascript:void(0)">
                      {item.link_name}
                    </a>
                  </li>)
                }
              </ul>
              <div className="destination-heading remove-float">
                <div className={`animated inner ${this.props.activeFold === true ? `fadeInLeft` : ``}`}>
                  <span className="destination-heading-bg"/>
                  <span className="destination-heading-bg right"/>
                  <div className='headingWrapper'>
                    <h3>
                      <small>{selectedTab && selectedTab.link_name}</small>
                      {selectedState}
                    </h3>
                    <CountInfo selectedTab={selectedTab}  />
                  </div>
                </div>
              </div>
            </div>
            <div className={`animated landing-page-india-map ${this.props.activeFold === true ? `fadeInUp` : ``}`}>
              <div className="inner-world-map">
                {
                  states && states.length
                    ? (<IndiaMap
                        states={states}
                        selectedTab={selectedTab}
                        setSelectedStateOrCountry={(state, totalCount, dateRange) => this.getSelectedStateOrCountry(state, totalCount, dateRange)}/>)
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({state}) => ({
  // clusterIndicatorsDetail: state.clusterIndicatorsDetail.data
});

const mapDispatchToProps = (dispatch) => ({
  getIndiaMapFootPrintData: () => dispatch(getIndiaMapFootPrintData()),
  getMapData: (url) => dispatch(getMapData(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageIndiaMap);
