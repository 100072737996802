import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CHART_TYPES, getFormattedNumber} from "../../../constant";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import Chart from "../../charts/Chart";
import {chartColors} from '../../../constant';
import {get} from 'lodash';

class TrendsWithSelectBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectListData: [],
      businessChart: {},
      trendsCharts: []
    }
  }

  componentDidMount() {
    const {data, selectBoxChartKey} = this.props;
    const trends = [];
    data && data.trends && data.trends.forEach((dataItem, i) => {

      const data = this.createSeriesData(dataItem);

      trends.push(this.createChartDOM(dataItem, i, data));
    });


    if (data[selectBoxChartKey]) {
      this.setState({
        selectListData: data[selectBoxChartKey].data
      }, () => {
        this.getSelectedBusinessChart(data[selectBoxChartKey].data[0], trends);
      });
    } else {
      this.setState({
        trendsCharts: trends
      });
    }
  }

  createSeriesData = (trend) => {
    const series = [{
      name: trend.trend_title,
      data: []
    }];
    const categories = [];

    trend.trend_data.forEach((data) => {
      series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
      categories.push(data.year);
    });
    const subtitle = trend.trend_count ? getFormattedNumber(Number(trend.trend_count)) : 0;
    const chartData = {
      title: trend.trend_title,
      series: series,
      subtitle: subtitle,
      categories,
    };
    return chartData;
  };

  createChartDOM = (dataItem, i, data, isAddSelect) => {
    const {selectListData} = this.state;
    const categoryName = get(selectListData, '[0].category_name');
    const defaultText = categoryName ? `${categoryName} (${dataItem.trend_count})` : '';
    return (<div className="graph-box trend-select-graph">
      <div style={{padding: '0 20px 0 20px'}}>
        <h3>
          <small>{dataItem.trend_as_on}</small>
          {dataItem.trend_title}
        </h3>
        {
          isAddSelect ? (
            <div className="">
              <div className="">
                <div className="select-box">
                  <select style={{ color: chartColors[i % 6] }} onChange={(e) => this.trendDropdownHandler(e.target.value)}>
                    <option value={JSON.stringify(selectListData[0])}>{defaultText}</option>
                    {selectListData.map((trend = {}, index) => {
                      const text = `${trend.category_name} (${trend.trend_count})`;
                      return index !== 0 ?
                        (<option style={{ color: '#000' }} value={JSON.stringify(trend)}>{text}</option>) : null;
                    })})}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <AnimatedCounter style={{ color: chartColors[i % 6] }} element="p" count={dataItem.trend_count}/>
          )
        }
      </div>
      <Chart options={{color: chartColors[i % 6]}} chartType={CHART_TYPES.AREA} data={data}/>
    </div>);
  };

  trendDropdownHandler = (selectedTrend) => {
    this.getSelectedBusinessChart(JSON.parse(selectedTrend), this.state.trendsCharts);
  };

  getSelectedBusinessChart = (trend, defaultTrends) => {
    const {data} = this.props;
    const chartData = this.createSeriesData(trend);
    defaultTrends[data.trends.length] = this.createChartDOM(trend, data.trends.length || 0, chartData, true);
    this.setState({
      trendsCharts: defaultTrends
    })
  };

  render() {
    const {trendsCharts} = this.state;

    return <div className="graph-sec">{trendsCharts}</div>;
  }
}

TrendsWithSelectBox.propTypes = {
  data: PropTypes.object
};

export default TrendsWithSelectBox;
