import React from 'react';

const SvgComponent = ({id, svgStyle, text, onSvgClick, fill, fill1, fill2}) => (<svg
	style={svgStyle}
	xmlns="http://www.w3.org/2000/svg"
	width="260px"
	height="240px"
	x="0px"
	y="0px"
	viewBox="0 0 1920 1200"
	className='svg'
	id={id}
>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="469.2204" y1="600.0001" x2="1450.7795" y2="600.0001">
		<stop offset="0" stopColor={'#D85A55'}/>
		<stop offset="1" stopColor={'#E78967'} />
	</linearGradient>
	<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="469.2204" y1="600.0001" x2="1450.7795" y2="600.0001">
		<stop  offset="0" stopColor="#1F9470"/>
		<stop  offset="1" stopColor="#48BFAD"/>
	</linearGradient>
	<g onClick={onSvgClick}>
		<path className="st0" fillRule="evenodd" clipRule="evenodd" fill={fill} d="M926.71,843.71c-251.73,0-321.16-184.32-457.49-232.39l0.64-36.54c136.24-57.84,225.08-218.47,456.85-218.47
 c289.44,0,524.07,109.06,524.07,243.71C1450.78,734.53,1216.16,843.71,926.71,843.71z"/>
		<path className="st1" fillRule="evenodd" clipRule="evenodd" fill={fill1} d="M926.71,356.29c-7.49,0-14.82,0.17-22,0.49c274.17,7.87,491.3,113.7,491.3,243.22
 c0,129.4-217.09,235.32-491.23,243.21c7.16,0.32,14.46,0.49,21.93,0.49c289.44,0,524.07-109.18,524.07-243.7
 C1450.78,465.36,1216.16,356.29,926.71,356.29z"/>
		<path className="st2" fillRule="evenodd" clipRule="evenodd" fill={fill2} d="M926.71,356.29c-3.77,0-7.49,0.05-11.18,0.13c281.94,3.98,507.86,111.46,507.86,243.58
 c0,132-225.9,239.58-507.83,243.58c3.68,0.08,7.39,0.13,11.14,0.13c289.44,0,524.07-109.18,524.07-243.7
 C1450.78,465.36,1216.16,356.29,926.71,356.29z"/>
		<foreignObject width="150" x="245" y="160" height="132" transform="scale(3)"><span
			title={text} className="ellipsis">{text}</span></foreignObject>
	</g>
</svg>);


export default SvgComponent;
