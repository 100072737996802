import React, { Component } from 'react';
import HighMaps from 'react-highcharts/ReactHighmaps.src';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import $ from 'jquery';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import Chart from '../../../charts/Chart';
import config from './indiaMapConfig';
import { CHART_TYPES } from "../../../../constant";
import States from './states';

class CustomHighMap extends Component {
  render() {
    const { selectedStateDetails } = this.props;

    let trends = '';
    if (selectedStateDetails && selectedStateDetails.trends) {
      let totalCount = 0;
      const series = [{
          name: selectedStateDetails.title,
          data: [],
      }];
      const categories = [];
      selectedStateDetails && selectedStateDetails.trends && selectedStateDetails.trends.map(data => {
          series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
          categories.push(data.year);
          totalCount += isNaN(Number(data.count)) ? 0 : Number(data.count);
      });
      totalCount = selectedStateDetails.total_count;
      let as_on_date = selectedStateDetails.as_on_date;
      const data = {
          title: '',
          series: series,
          subtitle: '',
          categories,
      };
      trends = (
        <div className="graph-box ml15 mt100">
          <div style={{padding: '20px 20px 0 20px'}}>
            {/* <AnimatedCounter element="p" count={dataItem.trend_count} /> */}
          </div>
          <Chart options={{height: 350}} chartType={CHART_TYPES.AREA} data={data}/>
        </div>
      )
    }
    return (
        <div id="india-map-container" style={{ position: 'relative' }}>
          <div className="graph-box-container">
            {trends}
          </div>
        </div>
    );
  }
}

CustomHighMap.propTypes = {
  states: PropTypes.array,
  setSelectedStateOrCountry: PropTypes.func,
  optionSelectedCountry: PropTypes.string
};

export default CustomHighMap;
