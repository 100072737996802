import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from "./CustomSelect";
import Chart from "../../charts/Chart";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import { chartColors, CHART_TYPES, getFormattedNumber, tableColors } from '../../../constant';

class EventsAndEngagements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: '',
      tabs: '',
      charts: [],
      selectedTab: {},
      selectedIndex: 0,
      isMenuOpen: false,
    };
  }

  componentDidMount() {
    const { data } = this.props.data.engagements;
    this.setState({
      selectedCountry: data[0],
    })
  }

  countryDropdownHandler = (selectedCountry) => {
    this.setState({ selectedCountry: selectedCountry, selectedTab:{}, selectedIndex: 0, isMenuOpen: false });
  };

  openCountryMenu = (e) => {
    this.setState({ isMenuOpen: true })
  };

  closeCountryMenu = () => {
    this.setState({ isMenuOpen: false })
  };

  setSelectedTab = (e, selectedTab, index) => {
    this.setState({selectedTab, selectedIndex: index})
  }

  createChartData = (selectedTabData) => {
    const trends = selectedTabData.map((dataItem, i) => {
      const series = [{
        name: 'Year',
        data: []
      }];
      const categories = [];

      dataItem.yearwise_data.forEach((data) => {
        series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
        categories.push(data.year);
      });
      const subtitle = dataItem.trend_count ? getFormattedNumber(Number(dataItem.trend_count)) : 0;
      const data = {
        title: '',
        series: series,
        subtitle: subtitle,
        categories,
      };

      return(
        {
          color: chartColors[i % 6],
          data,
          title: dataItem.mission_name,
          timeRange: dataItem.mission_year,
          totalCount: isNaN(Number(dataItem.mission_count)) ? 0 : dataItem.mission_count
        }
      )
    });
    return trends;
  }

  render() {
    const { data: countries } = this.props.data.engagements;
    const { trends: tabs } = this.state.selectedCountry;
    const { selectedTab, selectedIndex, selectedCountry, isMenuOpen } = this.state;
    const selectedTabData = (selectedTab && selectedTab.post_data) || (tabs && tabs[0] && tabs[0].post_data);
    const chartsData = selectedTabData && selectedTabData.length && this.createChartData(selectedTabData);
    const formatedCountries = countries.map((country) => {
      return {
        ...country,
        value: country.country_name,
        label: country.country_name,
      }
    });

    return (
      <section className="destination-outer events-engagement">
        <div className="wrapper">
          <div className="dropdown-tab-wrap clearfix">

            <div className="select-outer">
              <div className="select-inner">
                <div className={`drop-wrap ${isMenuOpen ? 'drop-wrap-open' : ''}`} onClick={this.openCountryMenu}>
                  <span>Select Country</span>
                  <span>{selectedCountry.country_name}</span>
                </div>
                <CustomSelect
                  formatedCountries={formatedCountries}
                  isMenuOpen={isMenuOpen}
                  countryDropdownHandler={this.countryDropdownHandler}
                  selectedCountry={selectedCountry}
                  closeMenu={this.closeCountryMenu}
                />
                <div className="select-box custom-select">
                  <select className="blue" onChange={(e) => this.countryDropdownHandler(e.target.value)}>
                    <option value={JSON.stringify(countries[0])}>{countries[0].country_name}</option>
                    {countries.map((country, index) => (
                      index !== 0 ?
                        (<option value={JSON.stringify(country)}>{country.country_name}</option>) : null
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="coordinate-tabs ">
              <div className="wrapper">
                <ul className="tabs">
                  {
                    tabs && tabs.map((tab, index) => {
                      return (<li
                        onClick={(e) => this.setSelectedTab(e, tab, index)}
                        className={ index === selectedIndex ? "active" : ''}>
                        <a href="javascript:void(0)" >{ tab.post_name }</a>
                      </li>)
                    })
                  }
                </ul>
              </div>
            </div>
          </div>

          <div className="destination-sec">
            <span className="destination-heading-bg" />
            <h3 className="sec-title">{(selectedTab && selectedTab.post_name) || (tabs && tabs[0] && tabs[0].post_name)}</h3>
            <div className="graph-sec">
              {
                selectedTabData && selectedTabData.length && selectedTabData.map((chart, index) => {
                  const colorPoints = tableColors[index % chartColors.length];
                  const color = `rgb(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]})`;
                  const background = `rgba(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]}, .1)`;
                  return (
                    <div className="graph-box event-engagement ent-table">
                      <div className="engagement-box ent-table-title-container">
                        <h3 style={{maxWidth: 'calc(100% - 80px)', display: 'inline-block', fontSize: '24px'}}>
                          <small>{chart.mission_year}</small>
                          {chart.mission_name}
                        </h3>
                        <AnimatedCounter style={{ color }} element="p" count={chart.mission_count}/>
                      </div>
                      <div>
                        <ul>
                          {
                            chart.yearwise_data.map(yearData => {
                              return (<li>
                                <div className="ent-table-row" style={{ background}}>
                                  <div className="ent-table-year">
                                    <div style={{ borderColor: color }} className="ent-table-row-radio" />
                                    <div style={{ color }}>
                                      {yearData.year}
                                    </div>
                                  </div>
                                  <div>{yearData.count}</div>
                                </div>
                              </li>)
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  );
                })
              }
              {/* <div className="graph-box pink">
								<h3>
									<small>2014 TO JUNE, 2019</small>
									Passport Issued In Abroad
								</h3>
								<p className="number" data-count="6107565" />
							</div>
							<div className="graph-box green">
								<h3>
									<small>2014 TO JUNE, 2019</small>
									Passport Issued In Abroad
								</h3>
								<p className="number" />
							</div>
							<div className="graph-box">
								<h3>
									<small>2014 TO JUNE, 2019</small>
									Passport Issued In India
								</h3>
								<p className="number" />
							</div>
							<div className="graph-box">
								<h3>
									<small>2014 TO JUNE, 2019</small>
									Passport Issuedmt In India
								</h3>
								<p className="number" data-count="56405460" />
							</div>
							<div className="graph-box">
								<h3>
									<small>2014 TO JUNE, 2019</small>
									Passport Issuedmt In India
								</h3>
								<p className="number" data-count="56405460" />
							</div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

EventsAndEngagements.propTypes = {
  data: PropTypes.object
};

export default EventsAndEngagements;
