export const INTRO_VIDEO_URL = '/videos/introVideo-small.mp4';

export const getFormattedNumber = (number) => number.toLocaleString(navigator.language, {minimumFractionDigits: 0});

export const CHART_TYPES = {
  AREA: 'area',
  BAR: 'bar',
  PIE: 'pie'
};

export const chartColors = [
  '#6dcc1c',
  '#ec6191',
  '#1089d7',
  '#ba93f5',
  '#6addb6',
  '#ed631b',
];

export const tableColors = [
  [109, 204, 28],
  [236, 97, 145],
  [16, 137, 215],
  [186, 147, 245],
  [106, 221, 182],
  [237, 99, 27],
];

export const defaultOptions = {
  color: '#1089d7',
  titleColor: '#1089d7',
  showDataLabels: true,
};

export const transparentOptions = {
  ...defaultOptions,
  titleColor: '#FFFFFF',
  backgroundColor: null,
  isTransparent: true,
};

export const areaChartData = {
  series: [{
    name: 'Years',
    data: [8129265, 10132398, 9706322, 11117688, 11491513, 5828274],
    color: '#FF1493',
  }],
  title: 'Passports Issued in India',
  subtitle: getFormattedNumber(56405460),
  categories: [2014, 2015, 2016, 2017, 2018, 2019],
};

export const barChartData = {
  title: 'Average Passport Issuance Time',
  categories: [2019, 2018, 2017, 2016, 2015, 2014],
  series: [{
    name: 'Tatkal',
    data: [107, 31, 635, 203, 2]
  }, {
    name: 'Normal',
    data: [133, 156, 947, 408, 6]
  }, {
    name: 'Others',
    data: [814, 841, 3714, 727, 31]
  }],
};

export const indiaMapColors = {
  hover: '#a4edba',
  selected: '#aaaaa',
}

export const pieChartData = {
  title: 'Browser market shares in January, 2018',
  series: [
    {
      name: 'Brands',
      // colorByPoint: true,
      data: [
        {
          name: 'Chrome',
          y: 61.41,
          // sliced: true,
          // selected: true
        }, {
          name: 'Internet Explorer',
          y: 11.84
        }, {
          name: 'Firefox',
          y: 10.85
        }, {
          name: 'Edge',
          y: 4.67
        }, {
          name: 'Safari',
          y: 4.18
        }, {
          name: 'Other',
          y: 7.05
        }]
    }],
};
