import React, {Component} from 'react';
import {getTrendsDataForWorldMap} from './trendsData';
import LeftSection from './LeftSection';
import './worldmap.css';

class WorldMap extends Component {
  render() {
    let data;
    const {indicatorId, selectedStateDetails} = this.props;

    if (selectedStateDetails && selectedStateDetails.trends && indicatorId) {
      data = getTrendsDataForWorldMap(selectedStateDetails, indicatorId);
    }

    return (
      <div className='inner-page-world-map' id="world-map-container" style={{position: 'relative'}}>
        <LeftSection selectedStateDetails={selectedStateDetails} data={data} indicatorId={indicatorId} />
      </div>
    )
  }
}

export default WorldMap
