import React from 'react';
import get from 'lodash/get';
import '../../landingPageIndiaMap/countInfo.css';

export default ({ selectedTab = {} }) => {
  if (!selectedTab || selectedTab.id === 3) {
    return null;
  }

  let description = '';
  let count = get(selectedTab, 'mapData.data[0].total_count');
  let asOnDate = '';

  if (selectedTab.id === 1) {
    description = get(selectedTab, 'mapData.summary[0].description', '');
    count = get(selectedTab, 'mapData.summary[0].title', '');
    asOnDate = get(selectedTab, 'mapData.summary[0].as_on_date', '')
  } else if (selectedTab.id === 2) {
    description = 'No of States with ICCR';
  } else if (selectedTab.id === 4) {
    description = 'POE Offices across India';
  }

  return <div className='countInfoOuterWrapper'>
    { asOnDate && <span className='asOnDate'>{asOnDate}</span> }
    <div className='countInfo'>
      <span className='totalCount'>{count}</span>
      <span className='totalCountDescription'>{description}</span>
    </div>
  </div>;
};
