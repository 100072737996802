import React from 'react';
import PropTypes from 'prop-types';
import './summary.css';

export const Summary = ({summary = []}) => {
  if (summary.length) {
    return <div className='summary'>
      <ul style={{display: "flex"}}>
        {
          summary.map(data => {
            return (<li style={{width: '50%'}}>
              <div style={{padding: '10px 20px 0px 0px'}}>
                <div style={{paddingBottom: '10px', fontSize: '35px', color: '#D3D3D3' }}>{data.description}</div>
                <div style={{color: '#A9A9A9'}}>{data.title}</div>
              </div>
            </li>)
          })
        }
      </ul>
    </div>;
  }

  return summary.count
    ? <div className="down-head clearfix">
      <div className="small-box">
        {summary.count ? <span>{summary.count}</span> : null}
        {summary.text ? <small>{summary.text}</small> : null}
      </div>
    </div>
    : null;
};

Summary.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Summary.defaultProps = {
  summary: [],
};

export default Summary;

