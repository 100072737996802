import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import Select from "react-select";

class CustomSelect extends Component {
  handleClickOutside = evt => {
    evt.stopPropagation();
    if (this.props.isMenuOpen) {
      this.timer = setTimeout(() => {
        this.props.closeMenu()
        clearTimeout(this.timer)
      }, 100)
    }
  };

  render() {

    const {
      options,
      isMenuOpen,
      onChange,
      selectedValue,
      defaultOption,
      openTowardTop,
    } = this.props;

    const menuStyles = openTowardTop ? {
      top: 'auto',
      bottom: 'calc(100% + 40px)',
    } : {};

    const colourStyles = {
      container: styles => {
        return {
          ...styles
        };
      },
      control: styles => ({
        ...styles,
        display: "none",
        position: "relative",
        bottom: 10
      }),
      menu: styles => ({
        ...styles,
        ...menuStyles,
        zIndex: 999,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          color: '#000',
        };
      },
    };

    return (
      <div>
        <Select
          defaultValue={defaultOption}
          label="Single select"
          id="test-id"
          options={options}
          styles={colourStyles}
          menuIsOpen={isMenuOpen}
          onChange={onChange}
          value={selectedValue}
        />
      </div>
    )
  }
}

export default onClickOutside(CustomSelect);