const data =  [{
  "name": "Africa",
  "color": "#e7c472",
  "data": ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "CD", "CG", "KM", "DJ", "EG", "GQ", "ER", "ET", "GM", "GA", "GH", "GN", "GW", "CI", "KE", "XK", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "ST", "SN", "SC", "SL", "SO", "ZA", "SS", "SD", "SZ", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"]
}, {
  "name": "Asia",
  "color": "#8cb35a",
  "data": ["AF", "BH", "BD", "BT", "BN", "KH", "CN", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "KP", "OM", "PK", "PH", "WA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TR", "TM", "AE", "UZ", "VN", "PS", "YE", "RU"]
}, {
  "name": "Europe",
  "color": "#56a6cb",
  "data": ["AL", "AD", "AM", "AT", "AZ", "BY", "BE", "BA", "BG", "HR", "CY", "CZ", "DK", "EE", "FO", "FI", "FR", "GE", "DE", "GR", "HU", "IS", "IE", "IM", "IT", "LV", "LI", "LT", "LU", "MK", "MT", "MD", "MC", "ME", "NL", "NO", "PL", "PT", "RO", "SM", "RS", "SK", "SI", "ES", "SE", "CH", "UA", "GB", "VA"]
}, {
  "name": "North America",
  "color": "#cd4845",
  "data": ["AI", "AW", "BS", "BB", "BZ", "BM", "CA", "KY", "CR", "CU", "CW", "DM", "DO", "SV", "PF", "US", "GL", "GD", "GT", "HT", "HN",  "MX","NI", "PA"]
}, {
  "name": "South America",
  "color": "#d8924c",
  "data": ["JM", "FM", "PY", "PE", "PR", "KN", "LC", "VC", "MF", "SR", "TT", "TC", "UY", "VE", "VI", "VG", "BR", "CO", "AR", "CL", "BO", "GY", "EC"]
}, {
  "name": "Oceania",
  "color": "#c570d8",
  "data": ["AS", "AU", "FJ", "GU", "KI", "MH", "NC", "NZ", "MP", "PW", "PG", "WS", "SB", "TP", "TO", "TV", "VU"]
}];


  const dataJson = data.map(item => {
    const obj = {
      name: item.name,
      color: item.color,
      data: []
    }
    item.data.forEach(country => {
      obj.data.push({
        "hc-key": country,
        "selected": country === "IN"
      })
    })
    return obj;
  })
  export default dataJson;
