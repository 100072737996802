import React from 'react';
import './infoCard.css';

export default ({selectedStateDetails = {}}) => {
  let imgUrl = '';
  if(selectedStateDetails.title === 'ICCR Regional Centers') imgUrl = '/images/iccr.png';
  else if(selectedStateDetails.title === 'MEA Videsh Bhawan') imgUrl = '/images/mvb.png';
  else if(selectedStateDetails.title === 'Protectorate of Emigrants') imgUrl = '/images/poe.png';
  return <div className='info-card'>
    <div className='img-wrap'>
      <img src={imgUrl}/>
    </div>
    <div className='content-wrap'>
      <div className="inner">
        <h4>{selectedStateDetails.title}</h4>
        {
          selectedStateDetails.states
          && selectedStateDetails.states.map((city, index) =>
            <p key={index}>{city}</p>
          )
        }
      </div>
    </div>
  </div>;
};
