import React, {Component} from 'react';
import WorldMap from './WorldMap/index';
import {connect} from 'react-redux';
import './map.css'

import {getGlobalMapHeader, getMapData} from '../../Actions';
import MapSlider from './template/slider';
import MapChart from './template/chart';
import SolarCard from './template/solar';
import PeaceCard from './template/peace';
import MapMission from './template/mission';
import MapTable from './template/table';
import './footprint.css';
import {getTrendsDataForBilateralTrade, getTrendsDataForGrantsAndLoans} from "../charts/WorldMap/trendsData";


import CustomSelect from '../reuseableComponents/CustomSelect';
import {Curtains} from 'curtainsjs';

class GlobalFootprint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [],
      selectedCountries: [
        'IN', 'PK'
      ],
      selectedCountryDropdown: {},
      selectedTabIndicator: 'mission-&-posts',
    }
  }

  componentDidMount() {
    this.startAnimation();
    this.props.getGlobalMapHeader()
      .then(data => {
        let headerData = data.map((data, id) => ({
          ...data, selected: false
        }));
        headerData[0].selected = true;
        let getMapsdata = [];
        headerData.length && headerData.forEach(mapUrlData => {
          getMapsdata.push(this.props.getMapData(mapUrlData.link_url))
        });
        Promise.all(getMapsdata)
          .then(values => {
            const mapData = [];
            headerData = headerData.map((data, index) => ({
              ...data, mapData: values[index]
            }));
            this.setState({headerData});
          })
          .catch(err => console.log('getAllMapsData globalFootprint Err ', err));
        return headerData;
      })
      .catch(err => console.log('getGlobalMapHeader Err ', err))
  }

  startAnimation = () => {
    // set up our WebGL context and append the canvas to our wrapper
    var webGLCurtain = new Curtains("canvas");

    // if there's any error during init, we're going to catch it here
    webGLCurtain.onError(function () {
      // we will add a class to the document body to display original images
      document.body.classList.add("no-curtains");
    });

    // get our plane element
    var planeElement = document.getElementsByClassName("plane")[0];

    // set our initial parameters (basic uniforms)
    var params = {
      vertexShaderID: "plane-vs", // our vertex shader ID
      fragmentShaderID: "plane-fs", // our framgent shader ID
      crossOrigin: "", // codepen specific
      uniforms: {
        time: {
          name: "uTime", // uniform name that will be passed to our shaders
          type: "1f", // this means our uniform is a float
          value: 0,
        },
      }
    }

    // create our plane mesh
    var plane = webGLCurtain.addPlane(planeElement, params);

    // if our plane has been successfully created
    // we use the onRender method of our plane fired at each requestAnimationFrame call
    plane && plane.onRender(function () {
      plane.uniforms.time.value++; // update our time uniform value
    });

  }

  handleTabClick(selectedTab) {
    const selectedTabIndicator = `${selectedTab.link_name}`.toLowerCase().replace(/\s/g, '-');
    const headerData = this.state.headerData.map((data) => ({
      ...data, selected: selectedTab.id === data.id
    }));
    this.setState({headerData, selectedCountryDropdown: {}, selectedTabIndicator});
  }

  getSelectedCountry(selectedCountry) {
    this.setState({selectedCountry});
  }

  getMapTemplate(selectedTabTemplate, selectedHeaderData) {
    const {selectedCountry} = this.state;
    const heading = selectedHeaderData.link_name;
    switch (selectedTabTemplate) {
      case 'slider':
        return (<MapSlider
          heading={selectedCountry && selectedCountry.country_name}
          selectedCountry={selectedCountry}
          subHeading={`List of ${heading}`}
          countSuffix='Project'
          showPrice
        />);
      case 'mahatma':
        return (<MapSlider
          heading={heading}
          selectedCountry={selectedCountry}
          subHeading=''
          tabClass="mahatma-slide"
          countSuffix='Statue'
          selectedHeaderData={selectedHeaderData}
        />);
      case "mahatma-gandhi-stamps":
        return (<MapSlider
          heading={heading}
          selectedCountry={selectedCountry}
          subHeading=''
          // tabClass="mahatma-slide"
          countSuffix='Stamp'
          selectedHeaderData={selectedHeaderData}
        />);
      case 'bilateral':
        return (<MapChart
          heading={heading}
          selectedCountry={selectedCountry}
          chartData={getTrendsDataForBilateralTrade(selectedCountry)}
          chartHeading='Total Trade'
        />);
      case 'grant':
        return (<MapTable
          heading={heading}
          selectedCountry={selectedCountry}
          chartData={getTrendsDataForGrantsAndLoans(selectedCountry)}
          chartHeading='Funds Allocated and Disbursed'
        />);
      case 'small_cart':
        return <SolarCard heading={heading} selectedCountry={selectedCountry} selectedHeaderData={selectedHeaderData}/>;
      case 'peace':
        return <PeaceCard heading={heading} selectedCountry={selectedCountry} selectedHeaderData={selectedHeaderData}/>;
      case 'mission':
        return <MapMission selectedHeaderData={selectedHeaderData} heading={heading}
                           selectedCountry={selectedCountry}/>;
      default:
        return null
    }
  }

  closeMenu = () => this.setState({isSelectOpen: false});

  onCountrySelect = (selectedCountryDropdown) => {
    this.setState({selectedCountryDropdown, selectedCountry: selectedCountryDropdown}, () => {
      this.closeMenu();
    });
  };

  render() {
    const {headerData, selectedCountryDropdown, selectedTabIndicator} = this.state;
    const selectedHeaderData = headerData.find(item => item.selected) || {};
    const {mapData: {data: selectedHeaderMapData = []} = {}} = selectedHeaderData;
    return (
      <section id="section2" className="section coordinate-section home-global-footprint">
        <div className={`animated footprint-heading ${this.props.activeFold === true ? `fadeInDown` : ``}`}>
          <div className="wrapper">
            <h3>MEA’s Global Footprint</h3>
          </div>
        </div>

        <div className={`animated coordinate-tabs ${this.props.activeFold === true ? `fadeInDown` : ``}`}>
          <div className="wrapper">
            <ul className="tabs">
              {
                headerData.map(data =>
                  <li className={`${data.selected ? 'active' : ''}`} onClick={() => this.handleTabClick(data)}>
                    <a href="javascript:void(0);">
                      {data.link_name}
                    </a>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
        <div className={`world-map ${selectedTabIndicator}`}>
          <div id="canvas"></div>

          <div className="plane">

            <img src="/images/coordinates-bg.jpg"/>
          </div>
          <div className="wrapper">
            <div
              className={`animated clearfix left-template-box tab${selectedHeaderData.id} ${this.props.activeFold === true ? `fadeInLeft` : ``}`}>
              {
                this.getMapTemplate(selectedHeaderData.link_type, selectedHeaderData)
              }
            </div>

            <div className={`animated word-map ${this.props.activeFold === true ? `fadeInUp` : ``}`}>
              <div className="map-inner">

                {/* <div className="word-map">
                <div className="map-inner"> */}
                {
                  selectedHeaderMapData && selectedHeaderMapData.length ?
                    (<WorldMap
                      selectedHeaderData={selectedHeaderData}
                      countriesData={selectedHeaderMapData}
                      getSelectedCountry={(data) => this.getSelectedCountry(data)}
                      selectedCountryDropdown={selectedCountryDropdown}
                    />) : null
                }
              </div>
              <div className="worldmap-dropdown" style={{cursor: 'pointer'}}>
                <div onClick={() => this.setState({isSelectOpen: true})} className="select-box">
                  {
                    `${selectedCountryDropdown.country_name || 'Select Country'}`
                  }
                </div>
                <CustomSelect
                  openTowardTop
                  value={selectedCountryDropdown}
                  isMenuOpen={this.state.isSelectOpen}
                  closeMenu={this.closeMenu}
                  onChange={this.onCountrySelect}
                  options={selectedHeaderMapData.map(country => {
                    return {
                      ...country,
                      value: country.country_name,
                      label: country.country_name,
                    }
                  })}
                />
              </div>
            </div>
            <div className="peice-disclaimer">
              {
                selectedHeaderData && selectedHeaderData.mapData
                && selectedHeaderData.mapData.disclaimer
                && selectedHeaderData.mapData.disclaimer.map(disclaimer => {
                  return (
                    <div>{disclaimer}</div>
                  )
                })
              }
            </div>
          </div>

        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGlobalMapHeader: () => dispatch(getGlobalMapHeader()),
  getMapData: (url) => dispatch(getMapData(url)),
});

export default connect(null, mapDispatchToProps)(GlobalFootprint);
