import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighCharts from 'react-highcharts';
import areaChartConfig from './areaChartConfig';
import { defaultOptions } from '../../constant';

export default class AreaChart extends Component {
	constructor(props) {
		super(props);
		ReactHighCharts.Highcharts.setOptions({
			lang: {
				thousandsSep: ','
			}
		})
	}
	render() {
		const { options, data } = this.props;
		return <ReactHighCharts config={areaChartConfig({ ...defaultOptions, ...options }, data)} />;
	}
}

AreaChart.propTypes = {
	chartType: PropTypes.string,
	data: PropTypes.object,
	options: PropTypes.object,
};

AreaChart.defaultProps = {
  data: { series: [] },
};

