import React from 'react';
import '../../../../Component/globalFootprint/template/mission.css';
import TemplateHeader from './templateHeader';
import get from 'lodash/get';

const footprintMissionCard = ({selectedCountry = {}, heading, selectedHeaderData}) => {
  return (
    <div>
      <TemplateHeader title={heading} summary={get(selectedHeaderData, 'mapData.summary')}/>
      <div className="footprint-mission-card clearfix">
        <div className="head-wrap clearfix">
          <h3>
            <span className="top">Missions & Posts in</span>
            <span className="bottom">{selectedCountry.country_name}</span>
          </h3>
          <span>{selectedCountry.trends && selectedCountry.trends.length}</span>
        </div>
        <div className="card-content">
          <ul>
            {selectedCountry.trends && selectedCountry.trends.map &&
            selectedCountry.trends.map(item => <li>{item.post_name}</li>)}
          </ul>
        </div>
      </div>
    </div>
  )
};

export default footprintMissionCard;
