import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './style.css';
import './App.css';
import './Component/landingPage/animation.css';


import IndicatorDetail from './Component/indicatorDetail'
import Home from './Component/landingPage';
import Inaugurated from './Component/InnerPages/Inaugurated';
import ScrollToTop from './Component/scrollToTop';
import Video from "./Component/video";
import ConfigProvider from './Component/reuseableComponents/ConfigProvider';

class App extends Component {
  state = {
    videoFinished: false,
  };

  setVideoFinished = () => {
    this.setState({ videoFinished: true });
  };

  componentDidMount = () => {
    if (window.location.pathname !== '/') {
      this.setVideoFinished();
    }
  };

  render() {
    const { videoFinished } = this.state;
    return (
      <div className="App">
        { !videoFinished && <Video onEnded={this.setVideoFinished}/> }
        <Router>
          <ConfigProvider>
            <div className={`${videoFinished ? 'fadeIn' : 'hide-content'}`}>
              <Route path="/" exact component={Home} />
              <Route path="/public/visits-dashboard" />
              <Route path="/pm-inaugurated" component={Inaugurated} />
              <ScrollToTop>
                <Route path="/indicators/:id" component={IndicatorDetail} />
              </ScrollToTop>
            </div>
          </ConfigProvider>
        </Router>

      </div>
    );
  }
}

export default App;
