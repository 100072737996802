import mapDataIndia from './india.json';
import mapDataIndiaReal from '@highcharts/map-collection/countries/in/custom/in-all-disputed.geo.json'
import allData from './states';
import find from 'lodash/find';

mapDataIndia.features.forEach(function(featureDisputed) {
  var feature = mapDataIndiaReal.features.find(function(feature) {
    return feature.properties.name === featureDisputed.properties.name;
  });
  if(feature) {
    featureDisputed.properties["hc-middle-x"] = feature.properties["hc-middle-x"];
    featureDisputed.properties["hc-middle-y"] = feature.properties["hc-middle-y"];
  }});


let intervalStopped = false;
let index = 0;

const config = (updateChartFn, data, i, { restart, showLabel, stopTimer }) => {
  // data.forEach((item, index) => {
  //   // if (i===index) item.selected = true;
  //   // else item.selected = false;
  // });

  const selectedState = find(data, { selected: true });
  const formatedData = allData.map((state) => {
    if (state.value === selectedState.value) {
      return {
        ...state,
        selected: true,
      }
    }

    return {
      ...state,
    }
  });

  let prevSelected = '';
  let initSelected = false;
  return ({
    chart: {
      map: 'countries/in/in-all',
      height: 550,
      width: 550,
      marginRight: 100,
      backgroundColor: 'transparent',
      overflow: 'unset',
      top: '30px',
      style: {
        fontFamily: 'Libel Suit',
        marginLeft: 100,
        overflow: 'unset',
        top: '30px',
      },
      events: {
        load: function () {
          if (!initSelected) showLabel();
          initSelected = true;
        }
      }
    },
    title: {
      text: ''
    },

    credits: { // hide text 'highcharts.com'
      enabled: false,
    },

    subtitle: {
      text: ''
    },
    // Allow zoom
    // mapNavigation: {
    //     enabled: true,
    //     buttonOptions: {
    //         verticalAlign: 'bottom'
    //     }
    // },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      snap: 1,
      positioner: function(w, h, p) {
        return {
          x: p.plotX,
          y: p.plotY + h / 2
        };
      }
    },
    series: [{
      color: '#fc983b',
      mapData: mapDataIndia,
      stickyTracking: false,
      point:{
        events:{
          mouseOver: function(e) {

          },
          click: function(e){
            if (prevSelected == this.name) return false;
            updateChartFn(this.name);
            prevSelected = this.name;
            var text = 'Selected ' + this.name + ' (' + this.value + '/km²)';
            var chart = this.series.chart;
            var  txt = 'y:  + this';
            index = this.value;
            intervalStopped = true;
            stopTimer();
            setTimeout(showLabel, 100);
          },
          select: function(a) {
            if (find(data, { ['hc-key']: this['hc-key'] })) {
              return true;
            }

            return false;
          },
          mouseOut: function() {
            if (intervalStopped) {
              intervalStopped = false;
              restart(index + 1);
            }
          }
        }
      },
      data: formatedData,
      name: 'State',
      shadow: true,
      allowPointSelect: true,
      states: {
        hover: {
          color: '#faf100',
        },
        select: {
          color: '#faf100',
          borderColor: 'red',
          dashStyle: 'dot',
          boxShadow: '5 10 blue'
        }
      },
      dataLabels: {
        enabled: true,
        allowOverlap: true,
        formatter: function() {
          if (find(data, { ['hc-key']: this.point['hc-key'] })) {
            return `<span style="cursor: pointer;color:#eb4564;height:40px;width:40px;font-size:30px; class="map-pointer auto-label-${this.point['hc-key']}">.</span>`;
          }
          return '<span></span>';
        }
      }
    }]
  });
};


export default config;
