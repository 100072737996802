import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { injectAppConfigs } from '../reuseableComponents/ConfigProvider';
import './header.css'

class Header extends Component {
  componentDidMount() {
    /**
     * Do not remove the setTimeout here as it is required for the
     * rendering the logo smoothly. If removed you will see a flicker
     * when the logo renders.
     */
    this.timer = setTimeout(() => {
      $('.header a:last-child').addClass('logoAnimaton');
      clearTimeout(this.timer);
    }, 1000)
  }
  render() {
    const { appConfigs } = this.props;
    return (
      <div className="wrapper header-wrap">
        <header className="header" style={{ textAlign: 'left'}}>
          <Link to="/" className="logo-mea">
            <img src="/images/logo-mea.svg" alt="" />
          </Link>
          <Link className='smartBoardLogo' to="/">
            {/* <img src="/images/smart-board-logo.png" alt="" /> */}
            Performance smart board
          </Link>

          {
            appConfigs && appConfigs.isDesktop === false ?
            <div className='visit-dashboard-wrap'>
              {/* <a className='visits-dashboard' target="_blank" href="http://dev-visit-dashboard.qa3.tothenew.net/"> */}
              <Link className='visits-dashboard' target="_blank" to="/visits-dashboard">
                <h3>Visits</h3>
              </Link>
            </div> :
            <div className='visit-dashboard-wrap'>
              {/* <a className='visits-dashboard' target="_blank" href="http://dev-visit-dashboard.qa3.tothenew.net/"> */}
              <Link className='visits-dashboard' target="_blank" to="/visits-dashboard">
                 <h3>Visits DashBoard <span>Click Here</span></h3>
              </Link>
            </div>
          }


        </header>
      </div>
    );
  }
}

export default injectAppConfigs(Header);
