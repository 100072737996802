import React,  { Component } from 'react';
import Modal from 'react-modal';
import {Link} from "react-router-dom";
import './footer.css';


Modal.setAppElement('#root');
const footerModalData = {
  privacy_policy: `<div>As a general rule, this website does not collect Personal Information about you when you visit the site. You can generally visit the site without revealing Personal Information.
  <h2>1. Site Visit Data</h2>
On this website, Ministry does not record your visit logs or other technical logs  such as your server's address; the name of the top-level domain from which you access the Internet (for example, .gov, .com, .in, etc.); the type of browser you use; the date and time you access the site; the pages you have accessed and the documents downloaded and the previous Internet address from which you linked directly to the site.
We will not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to inspect the service provider's logs.
<h2>2. Cookies</h2>
A cookie is a piece of software code that an internet web site sends to your browser when you access information at that site. This site does not use cookies.
<h2>3. Email Management</h2>
This website does not have provisions for dissemination of information to general public through email. Hence your email address will not be asked during your visit on this website.
<h2>4. Collection of Personal Information</h2>
This website does not collect any personal information. If at any time you believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the webmaster through email <b>meadb[at]mea[dot]gov[dot]in</b></div>`, 
  copyright_policy: '<div><p>This contents of this website may not be reproduced partially or fully, without due permission from Ministry of External Affairs, Govt. of India. If referred to as a part of another publication, the source must be appropriately acknowledged. The contents of this website can not be used in any misleading or objectionable context.</p></div>',
  hyperlinking_policy: `<div><p>Links to external websites/portals:
  This website does not have any hyperlink to any external website.
  
  Links to Dashboard by other websites:
  We do not object you linking directly to the information that is hosted on this Website and no prior permission is required for the same. However, we would like you to inform us about any links provided to this Website at <b>meadb[at]mea[dot]gov[dot]in</b>. Also, we do not permit our pages to be loaded into frames on your site. The pages belonging to this Website must load into a newly opened browser window of the User.
  </p></div>`, 
  terms: `<div><p>Though all efforts have been made to ensure the accuracy of the content on this Portal , the same should not be construed as a statement of law or used for any legal purposes. Ministry of External Affairs accepts no responsibility in relation to the completeness, exactness, usefulness or otherwise, of the contents. Users are advised to verify/check any information before acting on the information provided in the Portal.
  In no event, MEA will be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this Portal.</p></div>` 
}



export class Footer extends Component{
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      modalSelected:'privacy_policy'
    };
  }

  openModal = (event, modalSelected) => {
    event.preventDefault();
    this.setState({modalIsOpen: true,  modalSelected});
  }
  
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  
  render(){

    const {modalIsOpen, modalSelected} = this.state;
    return (
      <div>
        <footer className="footer">
          <div className="wrapper">
            <div className="footer-left">
              <p>
                <Link onClick={(event) => this.openModal(event, 'privacy_policy')}>Privacy Policy</Link>
                |
                <Link onClick={(event) => this.openModal(event, 'copyright_policy')}>Copyright Policy</Link>
                |
                <Link onClick={(event) => this.openModal(event, 'hyperlinking_policy')}>Hyperlinking Policy</Link>
                |
                <Link onClick={(event) => this.openModal(event, 'terms')}>Terms and Conditions</Link>
              </p>
              <p>© Content Owned by Ministry of External Affairs, Government of India.</p>
            </div>
            <div className="footer-right">
              <div>
                <img src="./images/envelope.png" alt="Envelope"/>
              </div>
              <div>
                <p>For feedback please</p>
                <p>reach out at <b>meadb[at]mea[dot]gov[dot]in</b></p>
              </div>
            </div>
            {/* <div className="footer-right">
              <p>Working hours at Headquarters 9:00 A.M. To 5:30 P.M.</p>
            </div> */}
          </div>
        </footer>
        <Modal
          className="footer-modal"
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(4, 6, 35, .88)',
            },
            content: {
              border: '0',
              padding: '0',
              top: '50%',
              left: '50%',
              overflow: 'unset',
              borderRadius: '20px',
              width: '95%',
              height: '95%',
              transform: 'translate(-50%, -50%)',
            }
          }}
        >
        <i onClick={this.closeModal} className="footer-close">
          <svg class="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path></svg>
        </i>
        { modalSelected &&
          <div className="footer-desc" dangerouslySetInnerHTML={{__html: footerModalData[modalSelected]}}>
          </div>
        }
        </Modal>
      </div>
    )
  }
}

export default Footer;


