import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import Chart from '../../../charts/Chart';
import { CHART_TYPES } from "../../../../constant";
import InfoCard from "../InfoCard";

class FootPrintInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configObj: {},
      selectedCountry: '',
      statesToBeMap:[]
    }
  }
  counter = 0;
  parentUpdated = false;

  componentDidMount() {
    const { states = [] } = this.props;
    this.handleDefaultStateSelect(states);
  }

  componentWillReceiveProps(nextProps) {
    const tabNotChanged = isEqual(this.props.states, nextProps.states);
    if (!tabNotChanged) {
      this.parentUpdated = false;
      this.handleDefaultStateSelect(nextProps.states);
    }
  }

  handleDefaultStateSelect = (states = []) => {
    if (
      states[0]
      && states[0].state_id === '8'
    ) {
      const mpState = find(states, { state_id: '78' });
      if (mpState) {
        this.setState({ selectedCountry: (mpState && mpState.state_name) })
      } else {
        this.setState({ selectedCountry: (states[0] && states[0].state_name) })
      }
    } else {
      this.setState({ selectedCountry: (states[0] && states[0].state_name) })
    }
  };

  prepareTrendsData = (selectedStateDetails) => {
    if (selectedStateDetails && selectedStateDetails.trends) {
      let totalCount = 0;
      const series = [{
        name: selectedStateDetails.title,
        data: []
      }];
      const categories = [];
      selectedStateDetails && selectedStateDetails.trends && selectedStateDetails.trends.map(data => {
        series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
        categories.push(data.year);
        totalCount += isNaN(Number(data.count)) ? 0 : Number(data.count);
      });
      const data = {
        title: '',
        series: series,
        subtitle: '',
        categories,
      };
      if (!this.parentUpdated) {
        this.props.setSelectedStateOrCountry(this.state.selectedCountry.trim(), totalCount, `${categories[0]} to ${categories[categories.length-1]}`);
        this.parentUpdated = true;
      }
      return (
        <div className="graph-box ml15 mt100">
          <div style={{padding: '20px 20px 0 20px'}}>
          </div>
          <Chart options={{height: 350}} chartType={CHART_TYPES.AREA} data={data}/>
        </div>
      )
    }
  };

  countryDropdownHandler = (selectedCountry) => {
    this.parentUpdated = false;
    this.setState({ selectedCountry })
  };

  render() {
    const { selectedTab, states = [] } = this.props;
    let selectedStateDetails = {};
    this.props.states.forEach(state => {
      if (state.state_name.trim() === this.state.selectedCountry.trim()) {
        selectedStateDetails = JSON.parse(JSON.stringify(state));
      }
    });
    let leftSection = '';
    const isTrendsTab = selectedTab.link_type === 'passport-sewa-kendra';

    if(selectedStateDetails.states) {
      if (!this.parentUpdated) {
        this.props.setSelectedStateOrCountry(this.state.selectedCountry.trim(), 0, ``);
        this.parentUpdated = true;
      }
    }

    if (isTrendsTab) {
      leftSection = this.prepareTrendsData(selectedStateDetails);
    } else {
      leftSection = <InfoCard selectedTab={selectedTab} selectedStateDetails={selectedStateDetails}/>;
    }

    return (
      <div id="india-map-container">
        <div className="select-box">
          <select
            value={this.state.selectedCountry}
            onChange={(e) => this.countryDropdownHandler(e.target.value)}>
            {states.map(state =>
              <option value={state.state_name}>
                {state.state_name}
              </option>)}
          </select>
        </div>
        <div className={`graph-box-container ${isTrendsTab ? '' : 'noGraph'}`}>
          {leftSection}
        </div>
      </div>
    );
  }
}

FootPrintInfo.propTypes = {
  states: PropTypes.array,
  setSelectedStateOrCountry: PropTypes.func,
};

export default FootPrintInfo;
