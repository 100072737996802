import React, {Component } from 'react';
import PropTypes from 'prop-types';
import './video.css';
import {INTRO_VIDEO_URL} from "../../constant";

export default class Video extends Component {
  state = {
    muted: true,
    videoPlaying: false,
  };

  toggleMute = () => {
    this.setState({ muted: !this.state.muted });
  };

  onPlay = () => {
    this.setState({ videoPlaying: true });
  };

  render() {
    const { onEnded } = this.props;
    const { muted, videoPlaying } = this.state;

    return <div className='videoWrapper'>
      <video
        className='video'
        muted={muted}
        autoPlay
        src={INTRO_VIDEO_URL}
        onEnded={onEnded}
        onPlay={this.onPlay}
        onLoadedData={this.onPlay}
      />
      {
        !videoPlaying && <div className='loadingIndicator'>
          <img src='/gifs/loader.gif' alt=''/>
          <span className='loadingText'>Loading..</span>
        </div>
      }
      <div className='buttonWrapper'>
        <div
          className={`button muteToggle ${muted ? 'muteIcon' : 'unmuteIcon'}`}
          onClick={this.toggleMute}
          role='presentation'
        />
        <div
          className='button skipIntro'
          onClick={onEnded}
          role='presentation'
        >
          Skip Intro
          <img src='/images/skip_icon.png' alt=''/>
        </div>
      </div>
    </div>;
  }
}


Video.propTypes = {
  onEnded: PropTypes.func,
};

Video.defaultProps = {
  onEnded() {},
};
