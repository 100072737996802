import mapDataIndia from '@highcharts/map-collection/custom/world-lowres.geo';
import deputedData from './desputed';
import chartData from './countries';
import $ from 'jquery';

deputedData.features.forEach(function(featureDisputed) {
  var feature = mapDataIndia.features.find(function(feature) {
    return feature.properties.name === featureDisputed.properties.name;
  });
  if(feature) {
    featureDisputed.properties["hc-middle-x"] = feature.properties["hc-middle-x"];
    featureDisputed.properties["hc-middle-y"] = feature.properties["hc-middle-y"];
  }});


let intervalStopped = false;
let index = 0;


const config = (id = 1, outdata, {stopTimer, showLabel, restart}, clickedfn, counter) => {
  // data.forEach((item, index) => {
  //   if (i===index) item.selected = true;
  //   else item.selected = false;
  // });
  
  return ({
    chart: {
      map: 'custom/world',
      height: 418,
      width: 1200,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    // Allow zoom
    // mapNavigation: {
    //     enabled: true,
    //     buttonOptions: {
    //         verticalAlign: 'bottom'
    //     }
    // },
    legend: {
      enabled: false
    },
    credits: { // hide text 'highcharts.com'
      enabled: false,
    },
    tooltip: {
      enabled: false,
      snap: 1,
      positioner: function(w, h, p) {
        return {
          x: p.plotX,
          y: p.plotY + h / 2
        };
      }
    },
    plotOptions: {
      map: {
        mapData: deputedData,
        allAreas: false,
        joinBy: ['iso-a2', 'code'],
        border: false,
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          style: {
            fontWeight: 'bold'
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.name}: <b>{series.name}</b>'
        }
      }
    },
    series: outdata,
  });
};


export default config;
