import { applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './Reducers'


const middlewares = applyMiddleware(thunkMiddleware);
let store = ''
if (typeof global.window === 'undefined') {
    store = createStore(rootReducer, compose(middlewares));
} else {
    /* eslint-disable no-underscore-dangle */
    const initialState = window.__PRELOADED_STATE__ || {};
    delete initialState.location;
    store = createStore(
        rootReducer,
        { ...initialState },
        composeWithDevTools(middlewares)
    );
    /* eslint-enable */
}
// const store = createStore(rootReducer, compose(middlewares))

export default store;