import React, {Component} from 'react';
import get from 'lodash/get';
import './solar.css';
import TemplateHeader from './templateHeader';

class footprintSolarCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    const {selectedCountry = {}, selectedHeaderData = {}, heading} = this.props;
    let imageSrc = '';
    let summary = {};
    let showLegends = false;
    if (selectedHeaderData.id === 9) { // "Indian Cultural Center"
      summary = {
        text: 'Countries with ICCs',
        count: get(selectedHeaderData, 'mapData.total_countries'),
      };
      imageSrc = '/images/indian-culture.png';
    } else if (selectedHeaderData.id === 3) { // "Solar Alliance"
      summary = get(selectedHeaderData, 'mapData.summary');
      showLegends = true;
      imageSrc = selectedCountry.country_type === "308" ? "/images/solar.png" : "/images/yellow-solar.png"
    } else if (selectedHeaderData.id === 8) { // Hindi Chairs
      summary = {
        text: 'Chairs Globally',
        count: get(selectedHeaderData, 'mapData.total_hindi_chair'),
      };
      imageSrc = '/images/hindi_chair.png';
    }
    return (
      <div>
        <TemplateHeader title={heading} summary={summary}/>
        <div className="solar-card clearfix">
          <div className="img-wrap">
            <img src={imageSrc}/>
          </div>
          <div className="content-wrap">
            <div className="inner">
              <h4>{selectedCountry.country_name}</h4>
              <small>{selectedCountry.trends && ((typeof selectedCountry.trends) !== 'string') ? selectedCountry.trends[0].title : selectedCountry.trends}</small>
            </div>
          </div>
        </div>
        {
          showLegends
            ? <div className='legendsWrapper'>
              <div className='legend'>
                <img className='legendImage' src='/images/pointer/solar.png'/>
                <span className='legendText'>Signed Country</span>
              </div>
              <div className='legend'>
                <img className='legendImage'  src='/images/pointer/yellow-solar.png'/>
                <span className='legendText'>Ratified Country</span>
              </div>
            </div>
            : null
        }
      </div>
    );
  }
}


export default footprintSolarCard;
