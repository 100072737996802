import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import AnimatedCounter from '../reuseableComponents/AnimatedCounter';

class Indicator extends Component {
  render() {
    let {nid, title, totalCount = '', imageUrl, imageAlt, asOnDate, weight} = this.props;
    // totalCount = totalCount.replace(/,/g, "");
    // totalCount = parseInt(totalCount);
    return (
      <li className="indicator">
        <Link to={`/indicators/${nid}`}>
          <div className="icon">
            <img src={imageUrl} alt={imageAlt}/>
          </div>
          <AnimatedCounter element="div" count={totalCount}/>
          <div className="title">{title}</div>
          <div className="date">{asOnDate}</div>
        </Link>
      </li>
    );
  }
}

export default withRouter(Indicator);
