import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import CustomHighMap from "./WorldMap";
import IndiaMap from "./India/indiaMap";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import '../../indicatorDetail/InnerPageMap/innerPageMap.css';

export default class InnerPageMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dropdownOptions: [],
      selectedOption: {},
      selectedStateDetails: {},
    }
  }

  componentDidMount() {
    this.createDropdownOptions(this.props);
  }

  componentWillReceiveProps({ mapDetails }) {
    if (!isEqual(mapDetails, this.props.mapDetails)) {
      this.createDropdownOptions({ mapDetails });
    }
  }

  createDropdownOptions = ({ mapDetails }) => {
    let labelKey = 'country_name';
    let valueKey = 'country_code';

    if (mapDetails.type === 'india') {
      labelKey = 'state_name';
      valueKey = 'state_name';
    }

    const dropdownOptions = get(mapDetails, 'data', []).map((item) => ({ ...item, value: item[valueKey], label: item[labelKey]}));
    this.setState({
      dropdownOptions,
      selectedOption: dropdownOptions[0]
    }, () => this.updateDataForChart(mapDetails));
  };

  updateDataForChart = (mapDetails) => {
    let selectedStateDetails = {};
    const { selectedOption } = this.state;

    const valueKey = mapDetails.type === 'india' ? 'state_name' : 'country_code';

    get(mapDetails, 'data', []).map(item => {
      if (get(item, valueKey, '') === selectedOption.value) {
        selectedStateDetails = JSON.parse(JSON.stringify(item));
      }
    });

    this.setState({ selectedStateDetails });
  };

  dropdownChangeHandler = (targetValue) => {
    const selectedOption = this.state.dropdownOptions.find((item) => item.value === targetValue);
    this.setState({selectedOption}, () => this.updateDataForChart(this.props.mapDetails));
  };

  render() {
    const {mapDetails, indicatorId} = this.props;
    const {dropdownOptions, selectedOption, selectedStateDetails = {}} = this.state;
    const totalCount = `${get(selectedStateDetails, 'total_count', '')}`.replace(/,/g, '');

    return <section className={`inner-page-map-section destination-outer ${indicatorId === '130' ? 'solar-alliance-outer' : ''}`}>
      <div className="wrapper">
        <div className="destination-sec">
          <div className="chart-outer">
            <div className="destination-heading">
              <div className="inner">
                <span className="destination-heading-bg"/>
                {
                  indicatorId !== '130' && (
                    <h3>
                      <small>{mapDetails.title}</small>
                      {selectedOption.label}
                    </h3>
                  )
                }
              </div>
            </div>
            {
              indicatorId === '192' || indicatorId === '130' || indicatorId === '92'
                ? null
                : <div className="destination-data-wrap">
                  <span>{selectedStateDetails.as_on_date}</span>
                  <AnimatedCounter data-count={totalCount} count={totalCount} element="strong"/>
                </div>
            }
          </div>
          <div className="inner-world-map-outer">
            <div className="select-outer">
              <div className="select-box">
                <select
                  onChange={(e) => this.dropdownChangeHandler(e.target.value)}
                  value={selectedOption && selectedOption.value}
                >
                  {dropdownOptions.map((item, index) =>
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>)}
                </select>
              </div>
            </div>
            <div className="inner-world-map">
              {
                mapDetails.type === 'india' ? (
                  <IndiaMap
                    selectedOption={selectedOption}
                    states={mapDetails.data}
                    selectedStateDetails={selectedStateDetails}
                  />
                ) : null
              }
              {
                mapDetails.type === 'world' ? (
                  <CustomHighMap
                    indicatorId={indicatorId}
                    selectedOption={selectedOption}
                    selectedStateDetails={selectedStateDetails}
                  />
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </section>;
  }
};
