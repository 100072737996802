import { chartColors, defaultOptions } from '../../../../constant';
import {bilateralTradeOptions, grantsOptions} from "./trendsConfig";

const getValueForKey = (data, key)=>{
  return isNaN(Number(data[key])) ? 0 : Number(data[key])
};

export const getTrendsData = (selectedStateDetails = {}, defaultSeries) => {
  const categories = [];
  const series = [...defaultSeries];

  selectedStateDetails.trends
  && selectedStateDetails.trends.length
  && selectedStateDetails.trends.forEach
  && selectedStateDetails.trends.forEach((yearWiseData) => {
    series.forEach(seriesItem => {
      const value = getValueForKey(yearWiseData, seriesItem.key);
      seriesItem.data.push(value);
      seriesItem.total += value;
    });
    categories.push(yearWiseData.year);
  });

  return { series, categories };
};

export const getTrendsDataForOtherMaps = (selectedStateDetails = {}) => {
  const defaultSeries = [{ name: selectedStateDetails.title, data: [], key: 'count', total: 0 }];
  const { series, categories } = getTrendsData(selectedStateDetails, defaultSeries);

  return {
    title: selectedStateDetails.title,
    series,
    subtitle: '',
    categories,
    totalCount: series[0].total,
    options: { ...defaultOptions, showDataLabels: true },
  };
};

export const getTrendsDataForBilateralTrade = (selectedStateDetails = {}) => {
  const bilateralSeries = [
    { name: 'Total Export', data: [], total: 0, key: 'export', minValue: 0, color: chartColors[0] },
    { name: 'Total Import', data: [], total: 0, key: 'import', minValue: 0, color: chartColors[1] },
    { name: 'Total Trade', data: [], total: 0, key: 'total', minValue: 0, color: chartColors[2] },
    { name: 'Total Balance', data: [], total: 0, key: 'balance', minValue: 0, color: chartColors[3] },
  ];
  const { series, categories } = getTrendsData(selectedStateDetails, bilateralSeries);

  return {
    title: selectedStateDetails.title,
    series,
    subtitle: '',
    categories,
    options: bilateralTradeOptions,
  };
};

export const getTrendsDataForGrantsAndLoans = (selectedStateDetails = {}) => {
  const bilateralSeries = [
    { name: 'Total Allocated', data: [], total: 0, key: 'allocated', minValue: 0, color: chartColors[0] },
    { name: 'Total Disbursed', data: [], total: 0, key: 'disbursed', minValue: 0, color: chartColors[1] },
  ];
  const { series, categories } = getTrendsData(selectedStateDetails, bilateralSeries);

  return {
    title: selectedStateDetails.title,
    series,
    subtitle: '',
    categories,
    options: grantsOptions,
  };
};

export const getTrendsDataForWorldMap = (selectedStateDetails = {}, indicatorId) => {
  if (indicatorId === '192') {
    return getTrendsDataForBilateralTrade(selectedStateDetails);
  } else if (indicatorId === '92') {
    return getTrendsDataForGrantsAndLoans(selectedStateDetails);
  } else if (indicatorId === '130') {
    return null;
  } else {
    return getTrendsDataForOtherMaps(selectedStateDetails);
  }
};
