export default (options, data) => ({
	chart: {
		type: 'bar',
		animation: true,
		height: 250,
		width: 350,
		marginTop: 32,
		backgroundColor: '#fafafa',
		style: {
			fontFamily: 'Libel Suit',
		}
	},
	title: {
		visible: false,
		align: 'left',
		text: data.title,
		margin: 24,
		style: {
			color: options.titleColor,
			fontSize: '24px',
			marginBottom: '24px',
		}
	},
	xAxis: {
		categories: data.categories,
		title: {
			text: null
		},
		lineWidth: 0,
		minorGridLineWidth: 0,
		lineColor: 'transparent',
		labels: {
			style: {
				color: options.isTransparent ? options.titleColor : options.color,
			},
		},
		minorTickLength: 0,
		tickLength: 0,
	},
	yAxis: {
		visible: false,
		min: 0,
		title: {
			text: null
		},
		labels: {
			overflow: 'justify'
		}
	},
	tooltip: {
		valueSuffix: ' millions' // todo fetch from data param
	},
	plotOptions: {
		bar: {
			dataLabels: {
				enabled: false,
			},
		},
		series: {
			animation: true,
			borderWidth: 0,
		}
	},
	// legend: {
	// 	layout: 'vertical',
	// 	align: 'right',
	// 	verticalAlign: 'top',
	// 	floating: true,
	// 	borderWidth: 1,
	// 	backgroundColor: '#FFFFFF',
	// 	shadow: true
	// },
	credits: {
		enabled: false
	},
	series: data.series,
});
