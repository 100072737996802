import React, {Component} from 'react';
import Indicator from '../indicator'
import {getClusters, getClusterIndicatorsById} from '../../Actions'
import {connect} from 'react-redux'
import RotationWheel from '../rotationWheel';

class Cluster extends Component {
	constructor(props) {
		super(props);
		this.state = {
      isRotationPaused: false,
    };
	}

	componentWillMount() {
		this.props.getClusters();
	}

	// componentDidMount() {

	// }

	componentWillReceiveProps(nextProps) {

	}

	restartAnimation = () => {
	  this.setState({ isRotationPaused: false })
  };

	stopAnimation = () => {
	  this.setState({ isRotationPaused: true })
  };

	render() {
		const {getClusterIndicatorsById, clusters = [], clusterIndicators = {}} = this.props;
		const { isRotationPaused } = this.state;
		const indicators = clusterIndicators.data && clusterIndicators.data.map && clusterIndicators.data.map(item => {
			return (<Indicator
				{...item}
			/>)
		});
		return (
			<div style={{textAlign:'left'}}>
				<section className="citizen-section wheel-content-container">
					<div>
						<div
              onMouseEnter={this.stopAnimation}
			  onMouseLeave={this.restartAnimation}
			  className={`animated ${this.props.activeFold === true ? `fadeInLeft` : ``}`}
            >
              <RotationWheel
                clusters={clusters}
                getClusterIndicatorsById={getClusterIndicatorsById}
				isRotationPaused={isRotationPaused}
				
              />
            </div>
						<div className={`animated citizen-right ${this.props.activeFold === true ? `fadeInUp` : ``}`}>
							<div className="head">
								<h3>{clusterIndicators.clusterName}</h3>
								<p>
									{clusterIndicators.clusterDescription}
								</p>
							</div>
							<ul
                className="services"
                onMouseEnter={this.stopAnimation}
                onMouseLeave={this.restartAnimation}
              >
								{indicators}
							</ul>
						</div>
					</div>
				</section>


			</div>
		);
	}
}

const mapStateToProps = ({state}) => ({
	clusters: state.clusters.data,
	clusterIndicators: state.clusterIndicators.data,
})

const mapDispatchToProps = (dispatch) => ({
	getClusters: () => dispatch(getClusters()),
	getClusterIndicatorsById: (id) => dispatch(getClusterIndicatorsById(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
