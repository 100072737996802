import React from 'react';
import GrantsAndLoans from "./grantsAndLoans";
import BasicAllTrends from "./BasicAllTrends";
import EventsAndEngagements from "./EventsAndEngagements";
import TrendsWithSelectBox from "./TrendsWithSelectBox";
import TabularView from "./TabularView";
import SocialMediaFollowers from "./SocialMediaFollowers";
import './../../../App.css'

export default class ChartTemplate extends React.Component {
  render() {
    const { indicatorId } = this.props;
    const {data, page_title} = this.props.indicatorDetailData;
    let chartData;
    if (!data) {
      chartData = null;
    } else if (data.grant) {
      chartData = <GrantsAndLoans data={data}/>;
    } else if (data.engagements) {
     return (<EventsAndEngagements data={data}/>);
    } else if (data.business) {
      chartData = <TrendsWithSelectBox data={data} selectBoxChartKey='business'/>;
    } else if (data.fields) {
      chartData = <TrendsWithSelectBox data={data} selectBoxChartKey='fields'/>;
    } else if (indicatorId === '133') { // Line of Credit
      chartData = <TabularView data={data} />;
    } else if (data.social) {
      chartData = <SocialMediaFollowers data={data}/>
    } else {
      chartData = <BasicAllTrends data={data} indicatorId={indicatorId}/>;
    }

    return chartData ?
      <section className="passport-report-view">
        <div className="wrapper">
          {chartData}
        </div>
      </section> : null;
  }
}
