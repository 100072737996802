import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighCharts from 'react-highcharts';
import ReactHighCharts3d from 'highcharts/highcharts-3d';
import pieChartConfig from './pieChartConfig';
import { pieChartData, defaultOptions } from '../../constant';
ReactHighCharts3d(ReactHighCharts.Highcharts)

export default class PieChart extends Component {
	constructor(props) {
		super(props);
		ReactHighCharts.Highcharts.setOptions({
			lang: {
				thousandsSep: ','
			}
		})
	}
	render() {
		const { options, data } = this.props;
		return <ReactHighCharts config={pieChartConfig(options || defaultOptions, data || pieChartData)} />;
	}
}

PieChart.propTypes = {
	chartType: PropTypes.string,
	data: PropTypes.object,
	options: PropTypes.object,
};
