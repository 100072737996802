import React from 'react';
import get from 'lodash/get';
import Chart from "../../../charts/Chart";
import {CHART_TYPES, tableColors, chartColors} from "../../../../constant";
import AnimatedCounter from "../../../reuseableComponents/AnimatedCounter";

const dimensions = {
  height: 400,
  width: 530,
  xAxis: {
    labels: {
      style: {
        fontSize:18,
      },
    },
  }
};

export const TableHeading = ({ indicatorId }) => {
  if (indicatorId !== '92') {
    return null;
  }

  return <table className='tableHeader'>
    <tr className='tableHeaderRow'>
      <td className='tableHeaderCell'>Year</td>
      <td className='tableHeaderCell'>Allocated</td>
      <td className='tableHeaderCell'>Disbursed</td>
    </tr>
  </table>;
};

export const TableRow = ({ indicatorId, yearData = {}, background, color }) => {
  const isLoc = indicatorId === '133';
  const { count, allocated, disbursed, year } = yearData;

  const countValue = `${isLoc ? '$' : ''}${count}${isLoc ? 'mn' : ''}`;
  const allocatedValue = `₹${allocated}cr`;
  const disbursedValue = `₹${disbursed}cr`;

  return <tr className="left-section-table-row" style={{ background }}>
    <td className="left-section-table-year">
      <div style={{ color }}>
        {year}
      </div>
    </td>
    { indicatorId === '92' ? <td><AnimatedCounter element="div" count={allocatedValue}/></td> : null }
    { indicatorId === '92' ? <td><AnimatedCounter element="div" count={disbursedValue}/></td> : null }
    { indicatorId !== '92' ? <td><AnimatedCounter element="div" count={countValue}/></td> : null }
  </tr>;
};

export default ({ selectedStateDetails, data, indicatorId }) => {
  if (indicatorId === '130') { // solar alliance
    let trends = selectedStateDetails.trends && selectedStateDetails.trends[0];
    if (selectedStateDetails.trends && Array.isArray(selectedStateDetails.trends)) {
      trends = selectedStateDetails.trends[0];
    } else if (selectedStateDetails.trends && typeof selectedStateDetails.trends === 'string') {
      trends = selectedStateDetails.trends;
    }
    return <div className="infoContainer solar-alliance-info">
      <div className="infoBox">
        {
          !!selectedStateDetails.country_name && (
            <div>
              <img
                className="solar-img"
                src={selectedStateDetails.country_type === '307' ? '/images/solar.png' : '/images/yellow-solar.png'}
                alt="solar"
              />
            </div>
          )
        }
        <div>
          <div className='countryName'>{selectedStateDetails.country_name}</div>
          <div className='description'>{trends}</div>
        </div>
      </div>
    </div>;
  } else if (indicatorId === '133' || indicatorId === '129' || indicatorId === '92') { // Line of Credit, KIP
    const colorPoints = tableColors[2];
    const color = chartColors[2];
    const background = `rgba(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]}, .1)`;
    return <div className='left-section-table'>
      <ul>
        <TableHeading indicatorId={indicatorId} background={background} color={color} />
        {
          get(selectedStateDetails, 'trends', []).map(yearData => {
            return (<li>
              <TableRow background={background} color={color} indicatorId={indicatorId} yearData={yearData}/>
            </li>)
          })
        }
      </ul>
    </div>
  }

  const options = data && data.options;

  return <div className="graph-box-container">
    <div className="graph-box ml15">
      <div style={{padding: '0 20px 0 20px'}}>
      </div>
      <Chart
        chartType={CHART_TYPES.AREA}
        options={{...options, ...dimensions}}
        data={data}
      />
    </div>
  </div>;
}
