import {defaultOptions} from "../../../constant";

export const bilateralTradeOptions = {
  ...defaultOptions,
  showDataLabels: false,
  xAxis: {
    crosshair: {
      width: 2,
      color: 'gray',
      dashStyle: 'shortdot'
    }
  },
  legend: {
    enabled: true,
  },
  tooltip: {
    borderColor: 'white',
    backgroundColor: 'white',
    shared: true,
    useHTML: true,
    split: false,
    style: {
      fontFamily: 'Libel Suit',
    },
    enabled: true,
    outside: false,
    headerFormat: '<span style="text-align:center;font-size:12px;padding-bottom: 8px;">Figures in USD ($) million</span><br>',
    pointFormat: `<table style="text-align:center;font-size:18px;color:{series.color}">
          <tr>
            <td style="padding:4px;color:#000">{series.name}: </td>
            <td align='right' style="text-align:right">$ {point.y}</td>
          </tr>
        </table>`,
  },
};

export const grantsOptions = {
  ...bilateralTradeOptions,
  tooltip: {
    ...bilateralTradeOptions.tooltip,
    headerFormat: '<span style="text-align:center;font-size:12px;padding-bottom: 8px;">Figures in Crores</span><br>',
    pointFormat: `<table style="text-align:center;font-size:18px;color:{series.color}">
          <tr>
            <td style="padding:4px;color:#000">{series.name}: </td>
            <td align='right' style="text-align:right">₹ {point.y}</td>
          </tr>
        </table>`,
  }
}
