import React, { Component } from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';

import '../../../Component/globalFootprint/map.css'
import { getGlobalMapHeader, getMapData } from '../../../Actions';
import MapSlider from './template/slider';
import MapChart from './template/chart';
import SolarCard from './template/solar';
import PeaceCard from './template/peace';
import MapMission from './template/mission';
import MapTable from './template/table';
import '../../../Component/globalFootprint/footprint.css';
import { getTrendsDataForBilateralTrade, getTrendsDataForGrantsAndLoans } from "../../charts/WorldMap/trendsData";
import CustomSelect from '../../reuseableComponents/CustomSelect';

class GlobalFootprint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [],
      selectedCountry: {},
    }
  }

  componentDidMount() {
    this.props.getGlobalMapHeader()
      .then(data => {
        let headerData = data.map((data, id) => ({
          ...data, selected: false
        }));
        headerData[0].selected = true;
        let getMapsdata = [];
        headerData.length && headerData.forEach(mapUrlData => {
          getMapsdata.push(this.props.getMapData(mapUrlData.link_url))
        });
        Promise.all(getMapsdata)
          .then(values => {
            headerData = headerData.map((data, index) => ({
              ...data, mapData: values[index]
            }));
            this.setState({ headerData });
            this.createDropdownOptions(headerData);
          })
          .catch(err => console.log('getAllMapsData globalFootprint Err ', err));
        return headerData;
      })
      .catch(err => console.log('getGlobalMapHeader Err ', err))
  }

  createDropdownOptions = headerData => {
    const selectedHeaderData = headerData.find(item => item.selected) || {};
    const dropdownOptions = get(selectedHeaderData, 'mapData.data', []).map(country => {
      return {
        ...country,
        value: country.country_name,
        label: country.country_name,
      }
    });

    this.setState({
      dropdownOptions,
      selectedCountry: dropdownOptions[0],
    });
  };

  handleTabClick(selectedTab) {
    const headerData = this.state.headerData.map((data) => ({
      ...data, selected: selectedTab.id === data.id
    }));

    this.setState({ headerData });
    this.createDropdownOptions(headerData);
  }

  getSelectedCountry(selectedCountry) {
    this.setState({ selectedCountry });
  }

  getMapTemplate(selectedHeaderData) {
    const { selectedCountry } = this.state;
    const { link_type: linkType, link_name: heading } = selectedHeaderData;

    switch (linkType) {
      case 'slider':
        return (<MapSlider
          heading={selectedCountry && selectedCountry.country_name}
          selectedCountry={selectedCountry}
          subHeading={`List of ${heading}`}
          countSuffix='Project'
          showPrice
        />);
      case 'mahatma':
        return (<MapSlider
          heading={heading}
          selectedCountry={selectedCountry}
          subHeading=''
          tabClass="mahatma-slide"
          countSuffix='Statue'
          selectedHeaderData={selectedHeaderData}
        />);
      case "mahatma-gandhi-stamps":
        return (<MapSlider
          heading={heading}
          selectedCountry={selectedCountry}
          subHeading=''
          // tabClass="mahatma-slide"
          countSuffix='Stamp'
          selectedHeaderData={selectedHeaderData}
        />);
      case 'bilateral':
        return (<MapChart
          heading={heading}
          selectedCountry={selectedCountry}
          chartData={getTrendsDataForBilateralTrade(selectedCountry)}
          chartHeading='Total Trade'
        />);
      case 'grant':
        return (<MapTable
          heading={heading}
          selectedCountry={selectedCountry}
          chartData={getTrendsDataForGrantsAndLoans(selectedCountry)}
          chartHeading='Funds Allocated and Disbursed'
        />);
      case 'small_cart':
        return <SolarCard heading={heading} selectedCountry={selectedCountry} selectedHeaderData={selectedHeaderData} />;
      case 'peace':
        return <PeaceCard heading={heading} selectedCountry={selectedCountry} selectedHeaderData={selectedHeaderData} />;
      case 'mission':
        return <MapMission selectedHeaderData={selectedHeaderData} heading={heading} selectedCountry={selectedCountry}/>;
      default:
        return null
    }
  }

  closeMenu = () => this.setState({ isSelectOpen: false });

  onCountrySelect = (selectedCountry) => {
    this.setState({ selectedCountry }, () => {
      this.closeMenu();
    });
  };

  render() {
    const { headerData, selectedCountry, dropdownOptions } = this.state;
    const selectedHeaderData = headerData.find(item => item.selected) || {};
    return (
      <section id="section2" className="section coordinate-section home-global-footprint">
        <div className={`animated footprint-heading ${this.props.activeFold === true ? `fadeInDown` : ``}`}>
          <div className="wrapper">
            <h3>MEA’s Global Footprint</h3>
          </div>
        </div>

        <div className={`animated coordinate-tabs ${this.props.activeFold === true ? `fadeInDown` : ``}`}>
          <div className="wrapper">
            <ul className="tabs">
              {
                headerData.map(data =>
                  <li className={`${data.selected ? 'active' : ''}`} onClick={() => this.handleTabClick(data)}>
                    <a href="javascript:void(0);">
                      {data.link_name}
                    </a>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
        <div className="world-map" >
          <div className="wrapper">
            <div className="worldmap-dropdown" style={{ cursor: 'pointer' }}>
              <div onClick={() => this.setState({ isSelectOpen: true })} className="select-box">
                {
                  `${selectedCountry.country_name || 'Select Country'}`
                }
              </div>
              <CustomSelect
                openTowardTop
                value={selectedCountry}
                isMenuOpen={this.state.isSelectOpen}
                closeMenu={this.closeMenu}
                onChange={this.onCountrySelect}
                options={dropdownOptions}
              />
            </div>
            <div className={`animated clearfix left-template-box tab${selectedHeaderData.id} ${this.props.activeFold === true ? `fadeInLeft` : ``}`}>
              {
                this.getMapTemplate(selectedHeaderData)
              }
            </div>
            <div className="peice-disclaimer">
              {
                selectedHeaderData && selectedHeaderData.mapData
                && selectedHeaderData.mapData.disclaimer
                && selectedHeaderData.mapData.disclaimer.map(disclaimer => {
                  return(
                    <div>{disclaimer}</div>
                  )
                })
              }
            </div>
          </div>

        </div>
      </section >
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGlobalMapHeader: () => dispatch(getGlobalMapHeader()),
  getMapData: (url) => dispatch(getMapData(url)),
});

export default connect(null, mapDispatchToProps)(GlobalFootprint);
