const disbursedOptions = (color) => {
    const pointStyleAllocated = `text-align:center;font-size:20px;color:${color}`;
    return ({
        showDataLabels: false,
        color,
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<span style="text-align:center;font-size:18px">{series.name}</span><br>',
          pointFormat: `<div style="${pointStyleAllocated}"><b>&#36; {point.y} mn</b></div>`,
        },
      });
}

export default disbursedOptions
