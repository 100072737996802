import { combineReducers } from 'redux'

const initState = {
  clusters: {
    data: [],
    isFetching: false
  },
  clusterIndicators: {
    data: [],
    isFetching: false
  },
  clusterIndicatorsDetail: {
      data: [],
      isFetching: false
  },
  homePageBanner: {
    data: [],
    isFetching: false
  },
  inaugurated: {
    data: [],
    isFetching: false
  },
  inauguratedCountry: {
    data: [],
    isFetching: false
  },
  countByCountry: {
    data: [],
    isFetching: false
  }
};

const MEAStore = (state = initState, action) => {
    switch (action.type) {
      case 'GET_CLUSTER_REQ':
        return {
          ...state,
            clusters: {
               isFetching: action.isFetching
            }
        }
    case 'GET_CLUSTER_SUCCESS':
        return {
            ...state,
              clusters: {
                  data: action.data,
                  isFetching: action.isFetching
              }
        }
    case 'GET_CLUSTER_FAILURE':
        return {
            ...state,
              clusters: {
                  errMessage: action.errMessage,
                  isFetching: action.isFetching
              }
        };
    case 'GET_CLUSTER_INDICATOR_REQ':
        return {
          ...state,
            clusterIndicators: {
               isFetching: action.isFetching
            }
        }
    case 'GET_CLUSTER_INDICATOR_SUCCESS':
      try {
        const a = {
          ...state,
          clusterIndicators: {
            data: action.data,
            isFetching: action.isFetching
          }
        }
        return {
          ...state,
          clusterIndicators: {
            data: action.data,
            isFetching: action.isFetching
          }
        }
      } catch (e) {
        console.log('error accruted ', e);
        return state
      }
        // return {
        //     ...state,
        //     clusterIndicators: {
        //           data: action.data,
        //           isFetching: action.isFetching
        //       }
        // };
    case 'GET_CLUSTER_INDICATOR_FAILURE':
        return {
            ...state,
            clusterIndicators: {
                  errMessage: action.errMessage,
                  isFetching: action.isFetching
              }
        }

    case 'GET_CLUSTER_INDICATOR_DETAIL_REQ':
        return {
          ...state,
            clusterIndicatorsDetail: {
               isFetching: action.isFetching
            }
        }
    case 'GET_CLUSTER_INDICATOR_DETAIL_SUCCESS':
        return {
            ...state,
            clusterIndicatorsDetail: {
                  data: action.data,
                  isFetching: action.isFetching
              }
        }
    case 'GET_CLUSTER_INDICATOR_DETAIL_FAILURE':
        return {
            ...state,
            clusterIndicatorsDetail: {
                  errMessage: action.errMessage,
                  isFetching: action.isFetching
              }
        }

    case 'GET_BANNER_REQ':
        return {
          ...state,
            homePageBanner: {
               isFetching: action.isFetching
            }
        }
    case 'GET_BANNER_SUCCESS':
        return {
            ...state,
            homePageBanner: {
                  data: action.data,
                  isFetching: action.isFetching
              }
        }
    case 'GET_BANNER_FAILURE':
        return {
            ...state,
            homePageBanner: {
                  errMessage: action.errMessage,
                  isFetching: action.isFetching
              }
        }
    case 'GET_INAUGURATED_REQ':
        return {
          ...state,
          inaugurated: {
               isFetching: action.isFetching
            }
        }
    case 'GET_INAUGURATED_SUCCESS':
        return {
            ...state,
            inaugurated: {
                  data: action.data,
                  isFetching: action.isFetching
              }
        }
    case 'GET_INAUGURATED_FAILURE':
        return {
            ...state,
            inaugurated: {
                  errMessage: action.errMessage,
                  isFetching: action.isFetching
              }
        };
    case 'GET_INAUGURATEDCOUNTRY_REQ':
      return {
        ...state,
        inauguratedCountry: {
          isFetching: action.isFetching
        }
      }
    case 'GET_INAUGURATEDCOUNTRY_SUCCESS':
      return {
        ...state,
        inauguratedCountry: {
          data: action.data,
          isFetching: action.isFetching
        }
      }
    case 'GET_INAUGURATEDCOUNTRY_FAILURE':
    return {
      ...state,
      inauguratedCountry: {
        errMessage: action.errMessage,
        isFetching: action.isFetching
      }
    };
    case 'GET_COUNTBYCOUNTRY_REQ':
      return {
        ...state,
        countByCountry: {
          isFetching: action.isFetching
        }
      }
    case 'GET_COUNTBYCOUNTRY_SUCCESS':
      return {
        ...state,
        countByCountry: {
          data: action.data,
          isFetching: action.isFetching
        }
      }
    case 'GET_COUNTBYCOUNTRY_FAILURE':
      return {
        ...state,
        countByCountry: {
          errMessage: action.errMessage,
          isFetching: action.isFetching
        }
      };
      default:
        return state
    }
  }

export default combineReducers({ state: MEAStore })
