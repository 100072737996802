import React from 'react';
import Summary from './summary';
import './templateHeader.css';

const templateHeader = ({title, subTitle, summary = {}}) => (<div className="footprint-left-head">
    <h2>
      {title}
      {subTitle ? <span className='subTitle'>{subTitle}</span> : null}
    </h2>
    <Summary summary={summary}/>
  </div>
);

export default templateHeader;
