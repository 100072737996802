import ReactHighCharts from 'react-highcharts';
import get from 'lodash/get';
import {commaSeparateNumber} from '../reuseableComponents/AnimatedCounter';

export const defaultTooltip = {
  shared: true,
  useHTML: true,
  headerFormat: '<span style="text-align:center;font-size:18px">{series.name}</span><br>',
  pointFormat: `<div style="text-align:center;font-size:20px;color:{series.color}"><b>{point.y}</b></div>`,
};

export default (options, data = {}) => {
  let minValue = {};
  get(data, 'series', []).forEach((item) => {
    minValue.series1 = Math.min.apply(null, item.data);
  });
  const color = get(data, 'series.color') || options.color;

  return({
    chart: {
      type: 'area',
      height: options.height || 200,
      // width: options.width || 390,
      backgroundColor: options.backgroundColor || 'transparent',
      boxShadow: 'none',
      style: {
        fontFamily: 'Libel Suit',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      reflow: true,
    },
    credits: { // hide text 'highcharts.com'
      enabled: false,
    },
    title: {
      visible: false,
      align: 'left',
      text: '',
      style: {
        fontSize: '24px',
      }
    },
    subtitle: {
      align: 'left',
      text: '',
      style: {
        fontSize: '24px',
        color,
      }
    },
    xAxis: {
      categories: data.categories,
      title: {
        text: null // not working
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        rotation: 0,
        style: {
          color,
        },
      },
      minorTickLength: 0,
      tickLength: 0,
      ...options.xAxis,
    },
    yAxis: {
      visible: false,
      startOnTick: false,
      // min: minValue.series1,
      gridLineWidth: 0,
    },
    plotOptions: {
      area: {
        dataLabels: {
          enabled: options.showDataLabels,
          style: {
            fontSize: '14px',
            color: '#666',
            fontWeight: 'normal',
            textOutline: false,
            crop: false,
            overflow: "none"
          },
          y: -6,
        },
        enableMouseTracking: true
      },
      series: {
        fillArea: true,
        fillColor: {
          linearGradient: [0, 0, 0, 200],
          stops: [
            [0, ReactHighCharts.Highcharts.Color(options.color).setOpacity(0.1).get('rgba')],
            [1, ReactHighCharts.Highcharts.Color(options.color).setOpacity(0).get('rgba')],
          ]
        },
        marker: {
          enabled: true,
          lineWidth: 1,
          lineColor: color,
          fillColor: 'rgba(255, 255, 255)',
        },
        color,
        dataLabels: {
          style: {
            fontSize: '18px',
            textOutline: '2px contrast',
          },
          formatter() {
            return commaSeparateNumber(this.point.y);
          }
        },
      }
    },
    legend: options.legend || { enabled: false },
    tooltip: options.tooltip || defaultTooltip,
    series: data.series,
  })};
