import React from 'react';
import get from "lodash/get";
import AnimatedCounter from "../../../reuseableComponents/AnimatedCounter";
import TemplateHeader from "./templateHeader";
import '../../../globalFootprint/template/table.css';
import {chartColors, tableColors} from "../../../../constant";

const MapTable = ({ selectedCountry = {}, heading, chartHeading }) => {
  const colorPoints = tableColors[0];
  const color = `rgb(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]})`;
  const background = `rgba(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]}, .1)`;

  return <div className='worldMapLandingPageChart'>
    <TemplateHeader title={heading} subTitle={get(selectedCountry, 'country_name')} />
    <div className="grants-and-loans graph-box">
      <div className='heading'>
        <h3>
          <small>{selectedCountry.as_on_date}</small>
          {chartHeading}
        </h3>
      </div>
      <div className='chartOuterWrapper'>
        <div className='homepage-global-map grants-and-loans-table'>
          <ul>
            <table className='tableHeader'>
              <tr className='tableHeaderRow'>
                <td className='tableHeaderCell'>Year</td>
                <td className='tableHeaderCell'>Allocated</td>
                <td className='tableHeaderCell'>Disbursed</td>
              </tr>
            </table>
            <div className='tableRowsWrapper'>
              {
                get(selectedCountry, 'trends', []).map((yearData) => {
                  return <li>
                    <tr className="grants-and-loans-table-row" style={{background}}>
                      <td className="grants-and-loans-table-year">
                        <div style={{color}}>{yearData.year}</div>
                      </td>
                      <td>
                        <AnimatedCounter element="div" count={`₹${(yearData.allocated)}cr`}/>
                      </td>
                      <td>
                        <AnimatedCounter element="div" count={`₹${(yearData.disbursed)}cr`}/>
                      </td>
                    </tr>
                  </li>
                })
              }
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>;

  return ;
};

export default MapTable;
