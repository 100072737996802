import React, { Component } from 'react';
import HighMaps from 'react-highcharts/ReactHighmaps.src';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import $ from 'jquery';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import Chart from '../Chart';
import config from './indiaMapConfig';
import { CHART_TYPES } from "../../../constant";
import States from './states';

class CustomHighMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configObj: {},
      selectedCountry: '',
      statesToBeMap:[]
    }
  }
  counter = 0;
  parentUpdated = false;

  makeCountryData = () => {
    const stateToMap = [];
    this.props.states.forEach((state, index) => {
        States.forEach((mapState, index) => {
          if(mapState['hc-key'] === state.state_name) {
            stateToMap.push({...mapState, ...{ selected: false }})
          }
          if (mapState['hc-key'] === state.state_name.toLowerCase()) {
            stateToMap.push({...mapState, ...{ selected: false }})
          }
        });
    });

    const mpState = find(stateToMap, { value: 40 });
    const mpStateIndex = findIndex(stateToMap, { value: 40 });
    if (mpState && stateToMap[0].value === 0) {
      mpState.selected = true;
      this.counter = mpStateIndex;
    } else {
      if (stateToMap[0].value < 35) {
        stateToMap[1].selected = true;
      } else {
        stateToMap[0].selected = true;
      }
      this.counter = 1;
      this.counter++;
    }

    this.setState({
      configObj: config(a => console.log(a), stateToMap, this.counter,{
        restart: this.beginTimer,
        showLabel: this.showLabel,
        stopTimer: this.stopTimer,
      }),
      statesToBeMap: stateToMap
    });
    this.counter++;
    this.showLabel();
    this.beginTimer(this.counter);
  };

  getCountryKey = (classArray) => {
    let key = null;
    for (let i = 0; i < classArray.length; i++) {
      if (classArray[i].match(/highcharts-key/g)) {
        let keyParts = classArray[i].split('-');
        for (let j = 2; j < keyParts.length; j++) {
          if (!key) {
            key = keyParts[j];
          } else {
            key += '-' + keyParts[j];
          }

        }
        break;
      }
    }
    return key;
  };

  getCountryName = (classArray) => {
    let key = null;
    for (let i = 0; i < classArray.length; i++) {
      if (classArray[i].match(/highcharts-name/g)) {
        const parts = classArray[i].split('-');
        key = '';
        for (let j = 2; j < parts.length; j++) {
          let nameParts = parts[j].split('');
          nameParts[0] = nameParts[0].toUpperCase();

          key += ' ' + nameParts.join('');
        }
        break;
      }
    }

    return key;
  };

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  beginTimer = (index) => {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      const data = this.state.statesToBeMap;
      if((this.counter == this.props.states.length - 1) || !data[this.counter]) {
        this.counter = 0;
        clearInterval(this.timer);
        this.beginTimer();
      }
      data.forEach((item, index) => {
        if (this.counter === index) {
          if(item.value < 35) {
            this.counter++;
            return;
          } else {
            item.selected = true;
          }
        } else {
          item.selected = false;
        }
      });

      this.setState({
        configObj: config(a => console.log(a), data, this.counter, {
          restart: this.beginTimer,
          showLabel: this.showLabel,
          stopTimer: this.stopTimer,
        }),
      }, () => {
        this.showLabel();
      });
      this.counter++;
    }, 15000)
  };

  showLabel = () => {
    const labelOffsetTop = 25;
    const labelOffsetLeft = 4;
    const classString = $('#india-map-container .highcharts-point-select').attr('class');
    if (classString) {
      const classes = classString.split(' ');
      const countryKey = this.getCountryKey(classes);
      const countryName = this.getCountryName(classes);
      const containerPosition = $('#india-map-container').offset();
      const itemPosition = $('#india-map-container .auto-label-' + countryKey).offset();
      if (countryName) {
        $('#india-map-container #india-map-tooltip').html(countryName)
        .css({
          'left': itemPosition.left - containerPosition.left + labelOffsetLeft,
          'top': (itemPosition.top - containerPosition.top) - labelOffsetTop,
        })
        .show();
        // this.props.setSelectedStateOrCountry(countryName);
        this.parentUpdated = false;
        this.setState({
          selectedCountry: countryName
        })
      }
    }
  }

  componentDidMount() {
    this.makeCountryData();
  }

  componentWillReceiveProps(nextProps) {
    const tabNotChanged = isEqual(this.props.states, nextProps.states);
    if (nextProps.optionSelectedCountry !== this.props.optionSelectedCountry) {
      clearInterval(this.timer);
      const {statesToBeMap} = this.state;
      statesToBeMap.forEach((item) => {
        if (item['hc-key'] === nextProps.optionSelectedCountry.toLowerCase()) {
            item.selected = true;
        } else {
          item.selected = false;
        }
      })
      this.setState({
        configObj: config(a => console.log(a), statesToBeMap, this.counter,{
          restart: this.beginTimer,
          showLabel: this.showLabel,
          stopTimer: this.stopTimer,
        }),
        statesToBeMap
      });
      setTimeout(() => {
        this.showLabel()
      }, 0)
    }


    if (!tabNotChanged) {
      clearInterval(this.timer);
      this.makeCountryData();
    }
  }

  render() {
    let selectedStateDetails = {}
    this.props.states.forEach(state => {
      if (state.state_name.trim() === this.state.selectedCountry.trim()) {
        selectedStateDetails = JSON.parse(JSON.stringify(state));
      }
    })
    let trends = '';
    if (selectedStateDetails && selectedStateDetails.trends) {
      let totalCount = 0;
      const series = [{
          name: selectedStateDetails.title,
          data: [],
      }];
      const categories = [];
      selectedStateDetails && selectedStateDetails.trends && selectedStateDetails.trends.map(data => {
          series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
          categories.push(data.year);
          totalCount += isNaN(Number(data.count)) ? 0 : Number(data.count);
      });
      totalCount = selectedStateDetails.total_count;
      let as_on_date = selectedStateDetails.as_on_date;
      const data = {
          title: '',
          series: series,
          subtitle: '',
          categories,
      };
      if (!this.parentUpdated) {
        this.props.setSelectedStateOrCountry(this.state.selectedCountry.trim(), totalCount, as_on_date);
        this.parentUpdated = true;
      }
      trends = (
        <div className="graph-box ml15 mt100">
          <div style={{padding: '20px 20px 0 20px'}}>
            {/* <AnimatedCounter element="p" count={dataItem.trend_count} /> */}
          </div>
          <Chart options={{height: 350, width: 560}} chartType={CHART_TYPES.AREA} data={data}/>
        </div>
      )
    }
    return (
        <div id="india-map-container" style={{ position: 'relative' }}>
          <div className="graph-box-container">
            {trends}
          </div>
          <div id="india-map-tooltip" className="tooltip-pinkpoint">
          </div>
          <div className="country-map-outer">
            <HighMaps config={this.state.configObj} />
          </div>
        </div>
    );
  }
}

CustomHighMap.propTypes = {
  states: PropTypes.array,
  setSelectedStateOrCountry: PropTypes.func,
  optionSelectedCountry: PropTypes.string
};

export default CustomHighMap;
