import mapDataIndia from '@highcharts/map-collection/custom/world-lowres.geo';
import deputedData from './desputed';
import $ from 'jquery';

deputedData.features.forEach(function(featureDisputed) {
  var feature = mapDataIndia.features.find(function(feature) {
    return feature.properties.name === featureDisputed.properties.name;
  });
  if(feature) {
    featureDisputed.properties["hc-middle-x"] = feature.properties["hc-middle-x"];
    featureDisputed.properties["hc-middle-y"] = feature.properties["hc-middle-y"];
  }});

let countriesData = [
  ['fo', 0],
  ['us', 1],
  ['jp', 2],
  ['in', 3],
  ['kr', 4],
  ['fr', 5],
  ['cn', 6],
  ['sw', 7],
  ['sh', 8],
  ['ec', 9],
  ['au', 10],
  ['ph', 11],
  ['es', 12],
  ['bu', 13],
  ['mv', 14],
  ['sp', 15],
  ['ve', 16],
  ['gb', 17],
  ['gr', 18],
  ['dk', 19],
  ['gl', 20],
  ['pr', 21],
  ['um', 22],
  ['vi', 23],
  ['ca', 24],
  ['tz', 25],
  ['cv', 26],
  ['dm', 27],
  ['sc', 28],
  ['nz', 29],
  ['ye', 30],
  ['jm', 31],
  ['om', 32],
  ['vc', 33],
  ['bd', 34],
  ['sb', 35],
  ['lc', 36],
  ['no', 37],
  ['cu', 38],
  ['kn', 39],
  ['bh', 40],
  ['fi', 41],
  ['id', 42],
  ['mu', 43],
  ['se', 44],
  ['ru', 45],
  ['tt', 46],
  ['br', 47],
  ['bs', 48],
  ['pw', 49],
  ['ir', 50],
  ['cl', 51],
  ['gw', 52],
  ['gd', 53],
  ['ee', 54],
  ['ag', 55],
  ['tw', 56],
  ['fj', 57],
  ['bb', 58],
  ['it', 59],
  ['mt', 60],
  ['pg', 61],
  ['de', 62],
  ['vu', 63],
  ['gq', 64],
  ['cy', 65],
  ['km', 66],
  ['va', 67],
  ['sm', 68],
  ['az', 69],
  ['am', 70],
  ['sd', 71],
  ['ly', 72],
  ['tj', 73],
  ['ls', 74],
  ['uz', 75],
  ['pt', 76],
  ['mx', 77],
  ['ma', 78],
  ['ar', 79],
  ['sa', 80],
  ['nl', 81],
  ['ae', 82],
  ['ke', 83],
  ['my', 84],
  ['ht', 85],
  ['do', 86],
  ['hr', 87],
  ['th', 88],
  ['cd', 89],
  ['kw', 90],
  ['ie', 91],
  ['mm', 92],
  ['ug', 93],
  ['kz', 94],
  ['tr', 95],
  ['er', 96],
  ['tl', 97],
  ['mr', 98],
  ['dz', 99],
  ['pe', 100],
  ['ao', 101],
  ['mz', 102],
  ['cr', 103],
  ['pa', 104],
  ['sv', 105],
  ['kh', 106],
  ['gt', 107],
  ['bz', 108],
  ['kp', 109],
  ['gy', 110],
  ['hn', 111],
  ['il', 112],
  ['ni', 113],
  ['mw', 114],
  ['tm', 115],
  ['zm', 116],
  ['nc', 117],
  ['za', 118],
  ['lt', 119],
  ['et', 120],
  ['gh', 121],
  ['si', 122],
  ['ba', 123],
  ['jo', 124],
  ['sy', 125],
  ['mc', 126],
  ['al', 127],
  ['uy', 128],
  ['cnm', 129],
  ['mn', 130],
  ['rw', 131],
  ['sx', 132],
  ['bo', 133],
  ['ga', 134],
  ['cm', 135],
  ['cg', 136],
  ['eh', 137],
  ['me', 138],
  ['rs', 139],
  ['bj', 140],
  ['tg', 141],
  ['af', 142],
  ['ua', 143],
  ['sk', 144],
  ['jk', 145],
  ['pk', 146],
  ['bg', 147],
  ['ro', 148],
  ['qa', 149],
  ['li', 150],
  ['at', 151],
  ['sz', 152],
  ['hu', 153],
  ['ne', 154],
  ['lu', 155],
  ['ad', 156],
  ['ci', 157],
  ['sl', 158],
  ['lr', 159],
  ['bn', 160],
  ['be', 161],
  ['iq', 162],
  ['ge', 163],
  ['gm', 164],
  ['ch', 165],
  ['td', 166],
  ['ng', 167],
  ['kv', 168],
  ['lb', 169],
  ['dj', 170],
  ['bi', 171],
  ['sr', 172],
  ['sn', 173],
  ['gn', 174],
  ['zw', 175],
  ['pl', 176],
  ['mk', 177],
  ['py', 178],
  ['by', 179],
  ['lv', 180],
  ['bf', 181],
  ['ss', 182],
  ['na', 183],
  ['la', 184],
  ['co', 185],
  ['ml', 186],
  ['cz', 187],
  ['cf', 188],
  ['sg', 189],
  ['vn', 190],
  ['tn', 191],
  ['mg', 192],
  ['eg', 193],
  ['so', 194],
  ['is', 195],
  ['lk', 196],
  ['np', 197],
  ['kg', 198],
  ['md', 199],
  ['bt', 200],
  ['bw', 201]
];

let intervalStopped = false;
let index = 0;

const config = (data, updateChartFn, i, { restart, showLabel, stopTimer }) => {
  if (data && data.length) {
    const selectedCountry = data.find((country) => (country.selected));
    countriesData = [...countriesData].map((country) => {
      if (Array.isArray(country)) {
        if (selectedCountry && selectedCountry['hc-key'] === country[0]) {
          return {
            ['hc-key']: country[0],
            value: country[1],
            selected: true,
          };
        }

        return {
          ['hc-key']: country[0],
          value: country[1],
          selected: false,
        };
      } else {
        if (selectedCountry && selectedCountry['hc-key'] === country['hc-key']) {
          return {
            ...country,
            selected: true,
          };
        }

        return {
          ...country,
          selected: false,
        };
      }
    });

  }
  return ({
    chart: {
      map: 'custom/world',
      height: 418,
      width: 700,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    // Allow zoom
    // mapNavigation: {
    //     enabled: true,
    //     buttonOptions: {
    //         verticalAlign: 'bottom'
    //     }
    // },
    legend: {
      enabled: false
    },
    credits: { // hide text 'highcharts.com'
      enabled: false,
    },
    tooltip: {
      enabled: false,
      snap: 1,
      positioner: function(w, h, p) {
        return {
          x: p.plotX,
          y: p.plotY + h / 2
        };
      }
    },
    series: [{
      color: '#70d03b',
      borderColor: '#70d03b',
      mapData: deputedData,
      stickyTracking: false,
      point:{
        events:{
          mouseOver: function(e) {

          },
          click: function(e){
            updateChartFn(this.name);
            var text = 'Selected ' + this.name + ' (' + this.value + '/km²)';
            var chart = this.series.chart;
            if (!chart.selectedLabel) {
              chart.selectedLabel = chart.renderer.label(text, 0, 620)
                .add();
            } else {
              chart.selectedLabel.attr({
                text: text
              });
            }
            var chart = this.series.chart,
              txt = 'y:  + this';
            index = i;
            intervalStopped = true;
            stopTimer();
            setTimeout(showLabel, 100);
          },
          select: function() {
            let countryExist = false;
            data.length && data.forEach(item=> {
              if (item['hc-key'] === this['hc-key']) countryExist = true;
            });
            return countryExist;
          },
          update: function() {
            return true;
          },
          mouseOut: function() {
            if (intervalStopped) {
              intervalStopped = false;
              restart(index + 1);
            }
          }
        }
      },
      data: countriesData,
      name: 'Countries',
      shadow: false,
      allowPointSelect: true,
      states: {
        hover: {
          color: '#fc983b',
        },
        select: {
          color: '#fc983b',
          borderColor: '#fc983b',
          dashStyle: 'dot',
          boxShadow: '5 10 blue'
        }
      },
      dataLabels: {
        enabled: true,
        useHTML: true,
        allowOverlap: true,
        formatter: function() {
          const showPointer = data.find((country) => country['hc-key'] === this.point['hc-key']);
          if (showPointer) {
            return `<span style="cursor: pointer;" class="pointer-label-inner-maps auto-label-${this.point['hc-key']}"></span>`
          } else {
            return `<span></span>`;
          }
        },
      }
    }]
  });
};


export default config;
