import React from 'react';
import CustomHighMap from "../../charts/WorldMap";
import IndiaMap from "../../charts/India/indiaMap";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import './innerPageMap.css';

export default class InnerPageMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedStateOrCountry: '',
      dateRange: '',
      totalCount: 0
    }
  }

  countryDropdownHandler = (optionSelectedCountry) => {
    this.setState({optionSelectedCountry})
  };


  getSelectedStateOrCountry = (state, total = '', dateRange) => {
    let totalCount = total && total.replace && total.replace(/,/g,'');
    this.setState({
      selectedStateOrCountry: state,
      dateRange,
      totalCount
    })
  }

  render() {
    const {countriesData, mapDetails, indicatorId} = this.props;

    return <section className={`destination-outer ${indicatorId === '130' ? 'solar-alliance-outer' : ''}`}>
      <div className="wrapper">
        <div className="destination-sec">
          <div className="chart-outer">
            <div className="destination-heading">
              <div className="inner">
                <span className="destination-heading-bg"></span>
                {
                  indicatorId !== '130' && (
                    <h3>
                      <small>{mapDetails.title}</small>
                      {this.state.selectedStateOrCountry}
                    </h3>
                  )
                }
              </div>
            </div>
            {
              indicatorId === '192' || indicatorId === '130' || indicatorId === '92'
                ? null
                : <div className="destination-data-wrap">
                  <span>{this.state.dateRange}</span>
                  {
                    !!this.state.totalCount && (
                      <AnimatedCounter count={this.state.totalCount} element="strong"/>
                    )
                  }
                </div>
            }
          </div>
          <div className="inner-world-map-outer">
            <div className="select-outer">
              <div className="select-box">
                <select onChange={(e) => this.countryDropdownHandler(e.target.value)}>
                  <option value="">Select</option>
                  {countriesData.map(country =>
                    <option value={mapDetails.type === 'india' ? country.state_name : country.country_code}>
                      {mapDetails.type === 'india' ? country.title : country.country_name}
                    </option>)}
                </select>
              </div>
            </div>
            <div className="inner-world-map">
              {
                mapDetails.type === 'india' ? (
                  <IndiaMap
                    optionSelectedCountry={this.state && this.state.optionSelectedCountry}
                    states={mapDetails.data}
                    setSelectedStateOrCountry={(state, totalCount, dateRange) => this.getSelectedStateOrCountry(state, totalCount, dateRange)}/>
                ) : null
              }
              {
                mapDetails.type === 'world' ? (
                  <CustomHighMap
                    indicatorId={indicatorId}
                    optionSelectedCountry={this.state && this.state.optionSelectedCountry}
                    countriesData={countriesData}
                    setSelectedStateOrCountry={(state, totalCount, dateRange) => this.getSelectedStateOrCountry(state, totalCount, dateRange)}
                  />
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </section>;
  }
};
