import React from 'react';
import {CHART_TYPES, getFormattedNumber} from "../../../constant";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import Chart from "../../charts/Chart";
import { chartColors } from '../../../constant';
import disbursedOptions from "./BasicAllTrendsChartOptions";

export default ({data, indicatorId}) => {
  const trends = [];
  data && data.trends && data.trends.forEach((dataItem, i) => {
    const series = [{
      name: dataItem.trend_title,
      data: []
    }];
    const categories = [];

    dataItem.trend_data.forEach((data) => {
      series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
      categories.push(data.year);
    });
    const subtitle = dataItem.trend_count ? getFormattedNumber(Number(dataItem.trend_count)) : 0;
    const data = {
      title: dataItem.trend_title,
      series: series,
      subtitle: subtitle,
      categories,
    };
    const defultOptions = indicatorId === '133' ?  disbursedOptions(chartColors[i % 6]) : {color: chartColors[i % 6]};

    trends.push(
      <div className="graph-box" style={{ position: 'relative' }}>
        <div style={{padding: '0 20px 0 20px'}}>
          <h3>
            <small>{dataItem.trend_as_on}</small>
            {dataItem.trend_title}
          </h3>
          <AnimatedCounter style={{ color: chartColors[i % 6] }} element="p" count={dataItem.trend_count}/>
          {
            dataItem.trend_icon ? (
              <div className="trend-image-container">
                <img src={dataItem.trend_icon} alt={dataItem.trend_title} />
              </div>
            ) : null
          }
        </div>
        <Chart options={defultOptions} chartType={CHART_TYPES.AREA} data={data}/>
      </div>
    )
  });
  return <div className="graph-sec">{trends}</div>;
};
