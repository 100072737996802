import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighCharts from 'react-highcharts';
import barChartConfig from './barChartConfig';
import { barChartData, defaultOptions } from '../../constant';

export default class BarChart extends Component {
	render() {
		const { options, series } = this.props;
		return <ReactHighCharts config={barChartConfig(options || defaultOptions, series || barChartData)} />;
	}
}

BarChart.propTypes = {
	chartType: PropTypes.string,
	series: PropTypes.array,
	options: PropTypes.object,
};
