import React, {Component} from 'react';
import Chart from "../../charts/Chart";
import {CHART_TYPES, chartColors} from '../../../constant';
import TemplateHeader from "./templateHeader";
import get from 'lodash/get';
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import './chart.css';

class MapChart extends Component {
  getOptions = () => {
    const { chartData } = this.props;
    return {
      ...chartData.options,
      height: 260,
      width: 432,
      backgroundColor: '#FFFFFF',
      tooltip: {
        ...chartData.options.tooltip,
        outside: false,
      },
      legend: {
        enabled: true,
      },
    };
  };

  render() {
    const {chartData, heading, chartHeading, selectedCountry = {}} = this.props;
    return (
      <div className='worldMapLandingPageChart'>
        <TemplateHeader title={heading} subTitle={get(selectedCountry, 'country_name')} />
        <div className="graph-box">
          <div className='heading'>
            <h3>
              <small>{selectedCountry.as_on_date}</small>
              {chartHeading}
            </h3>
          </div>
          <div className='chartOuterWrapper'>
            <Chart options={this.getOptions()} chartType={CHART_TYPES.AREA} data={chartData}/>
          </div>
        </div>
      </div>
    );
  }
}

export default MapChart;
