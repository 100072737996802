const data =  [
    ['fo', 0],
    ['us', 1],
    ['jp', 2],
    ['in', 3],
    ['kr', 4],
    ['fr', 5],
    ['cn', 6],
    ['sw', 7],
    ['sh', 8],
    ['ec', 9],
    ['au', 10],
    ['ph', 11],
    ['es', 12],
    ['bu', 13],
    ['mv', 14],
    ['sp', 15],
    ['ve', 16],
    ['gb', 17],
    ['gr', 18],
    ['dk', 19],
    ['gl', 20],
    ['pr', 21],
    ['um', 22],
    ['vi', 23],
    ['ca', 24],
    ['tz', 25],
    ['cv', 26],
    ['dm', 27],
    ['sc', 28],
    ['nz', 29],
    ['ye', 30],
    ['jm', 31],
    ['om', 32],
    ['vc', 33],
    ['bd', 34],
    ['sb', 35],
    ['lc', 36],
    ['no', 37],
    ['cu', 38],
    ['kn', 39],
    ['bh', 40],
    ['fi', 41],
    ['id', 42],
    ['mu', 43],
    ['se', 44],
    ['ru', 45],
    ['tt', 46],
    ['br', 47],
    ['bs', 48],
    ['pw', 49],
    ['ir', 50],
    ['cl', 51],
    ['gw', 52],
    ['gd', 53],
    ['ee', 54],
    ['ag', 55],
    ['tw', 56],
    ['fj', 57],
    ['bb', 58],
    ['it', 59],
    ['mt', 60],
    ['pg', 61],
    ['de', 62],
    ['vu', 63],
    ['gq', 64],
    ['cy', 65],
    ['km', 66],
    ['va', 67],
    ['sm', 68],
    ['az', 69],
    ['am', 70],
    ['sd', 71],
    ['ly', 72],
    ['tj', 73],
    ['ls', 74],
    ['uz', 75],
    ['pt', 76],
    ['mx', 77],
    ['ma', 78],
    ['ar', 79],
    ['sa', 80],
    ['nl', 81],
    ['ae', 82],
    ['ke', 83],
    ['my', 84],
    ['ht', 85],
    ['do', 86],
    ['hr', 87],
    ['th', 88],
    ['cd', 89],
    ['kw', 90],
    ['ie', 91],
    ['mm', 92],
    ['ug', 93],
    ['kz', 94],
    ['tr', 95],
    ['er', 96],
    ['tl', 97],
    ['mr', 98],
    ['dz', 99],
    ['pe', 100],
    ['ao', 101],
    ['mz', 102],
    ['cr', 103],
    ['pa', 104],
    ['sv', 105],
    ['kh', 106],
    ['gt', 107],
    ['bz', 108],
    ['kp', 109],
    ['gy', 110],
    ['hn', 111],
    ['il', 112],
    ['ni', 113],
    ['mw', 114],
    ['tm', 115],
    ['zm', 116],
    ['nc', 117],
    ['za', 118],
    ['lt', 119],
    ['et', 120],
    ['gh', 121],
    ['si', 122],
    ['ba', 123],
    ['jo', 124],
    ['sy', 125],
    ['mc', 126],
    ['al', 127],
    ['uy', 128],
    ['cnm', 129],
    ['mn', 130],
    ['rw', 131],
    ['sx', 132],
    ['bo', 133],
    ['ga', 134],
    ['cm', 135],
    ['cg', 136],
    ['eh', 137],
    ['me', 138],
    ['rs', 139],
    ['bj', 140],
    ['tg', 141],
    ['af', 142],
    ['ua', 143],
    ['sk', 144],
    ['jk', 145],
    ['pk', 146],
    ['bg', 147],
    ['ro', 148],
    ['qa', 149],
    ['li', 150],
    ['at', 151],
    ['sz', 152],
    ['hu', 153],
    ['ne', 154],
    ['lu', 155],
    ['ad', 156],
    ['ci', 157],
    ['sl', 158],
    ['lr', 159],
    ['bn', 160],
    ['be', 161],
    ['iq', 162],
    ['ge', 163],
    ['gm', 164],
    ['ch', 165],
    ['td', 166],
    ['ng', 167],
    ['kv', 168],
    ['lb', 169],
    ['dj', 170],
    ['bi', 171],
    ['sr', 172],
    ['sn', 173],
    ['gn', 174],
    ['zw', 175],
    ['pl', 176],
    ['mk', 177],
    ['py', 178],
    ['by', 179],
    ['lv', 180],
    ['bf', 181],
    ['ss', 182],
    ['na', 183],
    ['la', 184],
    ['co', 185],
    ['ml', 186],
    ['cz', 187],
    ['cf', 188],
    ['sg', 189],
    ['vn', 190],
    ['tn', 191],
    ['mg', 192],
    ['eg', 193],
    ['so', 194],
    ['is', 195],
    ['lk', 196],
    ['np', 197],
    ['kg', 198],
    ['md', 199],
    ['bt', 200],
    ['bw', 201]
  ];
  

  const dataJson = data.map(item => {
    return {
      "hc-key": item[0],
      "value": item[1]
    }
  })
  
  export default dataJson;