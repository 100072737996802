export default (options, data) => ({
  credits: {
    enabled: false
  },
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,
		type: 'pie',
		height: 200,
		width: 160,
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0
    },
    marginBottom : '50',
    style: {
		  fontSize: '24px',
			fontFamily: 'Libel Suit',
		},
	},
	title: {
    y: -10,
    text: data.title,
    style: {
      fontSize: '24px',
    },
    verticalAlign: 'bottom',
  },
  subtitle: {
    verticalAlign: 'bottom',
    text: data.subtitle,
    style: {
      fontSize: '16px',
      // color: options.color,
    }
  },
  tooltip: {
    shared: true,
    useHTML: true,
    headerFormat: '<span style="text-align:center;font-size:18px">{point.key}</span><br>',
    pointFormat: `<div style="text-align:center;font-size:20px;color:{point.color};"><b>{point.percentage:.1f}%</b></div>`,
  },
	plotOptions: {
		pie: {
			allowPointSelect: true,
			cursor: 'pointer',
      depth: 25,
			dataLabels: {
				enabled: false
			},
			showInLegend: false,
		}
	},
	series: data.series,
});
