const colorsArray = [
  '#1abc9c',
  '#3498db',
  '#2ecc71',
  '#9b59b6',
  '#f1c40f',
  '#34495e',
  '#e67e22',
  '#e74c3c',
  '#2980b9',
  '#95a5a6',
  '#16a085',
  '#27ae60',
  '#8e44ad',
  '#d35400',
  '#7f8c8d',
  '#2c3e50',
  '#f39c12',
  '#c0392b',
  '#bdc3c7',
];

export const formatNumber = (value) => Number(value.toFixed(2));

export const grantsAndLoansTrends = (grants = {}) => {
  const { yearwise, allocated_range = '', disbursed_range = '' } = grants;
  const years = [];
  const allocatedSeries = [];
  const disbursedSeries = [];

  for (const [year, yearData] of Object.entries(yearwise)) {
    years.push(year);
    allocatedSeries.push(formatNumber(yearData.allocated));
    disbursedSeries.push(formatNumber(yearData.disbursed));
  }

  const allocatedDataTrends = {
    series: [{data: allocatedSeries, name: 'Total Funds Allocated'}],
    categories: years,
    asOnDate: allocated_range,
  };

  const disbursedDataTrends = {
    series: [{data: disbursedSeries, name: 'Total Funds Disbursed'}],
    categories: years,
    asOnDate: disbursed_range,
  };

  return { allocatedDataTrends, disbursedDataTrends };
};

export const grantsAndLoansPie = (countryWiseGrants) => {

  const yearWiseAllocatedSeries = [];
  const yearWiseDisbursedSeries = [];

  let overAllAllocatedTotal = 0, overAllDisbursedTotal = 0;

  const allocatedCharts = [];
  const disbursedCharts = [];

  const legendColorMap = {};
  let colorIndex = 0;
  for (const [year, countriesDataForYear] of Object.entries(countryWiseGrants)) {
    let yearlyAllocatedTotal = 0, yearlyDisbursedTotal = 0;
    const countryWiseAllocatedData = [];
    const countryWiseDisbursedData = [];

    countriesDataForYear.forEach(countryData => {
      let color = '';
      if (Object.keys(legendColorMap).indexOf(countryData.country_name) > -1) {
        color = legendColorMap[countryData.country_name];
      } else {
        color = colorsArray[colorIndex % colorsArray.length];
        legendColorMap[countryData.country_name] = color;
        colorIndex++;
      }
      const y1 = isNaN(countryData.allocated) ? 0 : Number(countryData.allocated);
      yearlyAllocatedTotal += y1;
      countryWiseAllocatedData.push({name: countryData.country_name, y: y1, color});

      const y2 = isNaN(countryData.disbursed) ? 0 : Number(countryData.disbursed);
      yearlyDisbursedTotal += y2;
      countryWiseDisbursedData.push({name: countryData.country_name, y: y2, color});
    });

    const yearDataForAllocated = {
      name: year,
      data: countryWiseAllocatedData
    };

    const yearDataForDisbursed = {
      name: year,
      data: countryWiseDisbursedData,
    };

    allocatedCharts.push({
      title: '₹' + yearlyAllocatedTotal.toFixed(2) + ' cr',
      series:[yearDataForAllocated],
      subtitle: year,
    });

    disbursedCharts.push({
      title: '₹' +  yearlyDisbursedTotal.toFixed(2) + ' cr',
      series: [yearDataForDisbursed],
      subtitle: year,
    });

    yearWiseAllocatedSeries.push({
      series: countryWiseAllocatedData,
    });

    yearWiseDisbursedSeries.push({
      data: countryWiseDisbursedData,
    });

    overAllAllocatedTotal += yearlyAllocatedTotal;
    overAllDisbursedTotal += yearlyDisbursedTotal;
  }

  const allocatedPieChartData = {
    title: 'Countrywise Funds Allocated',
    series: yearWiseAllocatedSeries
  };

  const disbursedPieChartData = {
    title: 'Countrywise Funds Disbursed',
    series: yearWiseDisbursedSeries,
  };

  return {
    allocatedPieChartData,
    disbursedPieChartData,
    overAllAllocatedTotal,
    overAllDisbursedTotal,
    allocatedCharts,
    disbursedCharts,
    legendColorMap,
  };
};
