import React, { Component } from 'react';
import $ from 'jquery';
export function commaSeparateNumber(num) {
  let number = num.toString();
  let str = []
  for (let i = number.length - 1, count = 1; i >= 0; i-- , count++) {
    if (count == 4 || count === 6 || count === 8)
      str.push(',');
    str.push(number[i]);
  }
  const value = str.reverse().join("")
  return value;
}

function getSubTextData(val) {
  if (/^\d+$/.test(val)) {
    return {};
  } else {
    val = `${val}`;
    const startIndex = val.search(/\d/);
    const endIndex = `${val}`.length - (val.split('').reverse().join('').search(/\d/) + 1 || 0);
    let num = '';
    let strSt = '';
    let strEn = '';
    if (startIndex === 0) {
      num = val.substr(0, endIndex + 1);
      strEn = val.substr(endIndex + 1);
    } else if (endIndex === `${val}`.trim().length) {
      num = val.substr(startIndex);
      strSt = val.substr(0, startIndex);
    } else {
      num = val.substr(startIndex, endIndex );
      strSt = val.substr(0, startIndex);
      strEn = val.substr(endIndex + 1);
    }

    if (num.indexOf('.') > -1) {
      const partAfterString = num.substr(num.indexOf('.'));
      num = num.substr(0, num.indexOf('.'));
      strEn = partAfterString + strEn;
    }

    return {
      num,
      strEn,
      strSt,
    };
  }
}

class AnimatedCounter extends Component {

  constructor() {
    super();
    this.prefix = `${Math.floor(Math.random() * 1000000)}`;
  }

  componentDidMount() {
    $(`.number-${this.prefix}`).each(function () {
      let $this = $(this);
      const countTo = $this.attr('data-count');
      const strSt = $this.attr('data-str-st');
      const strEn = $this.attr('data-str-en');
      if (!countTo || !/^\d+$/.test(countTo)) {
        return;
      }
      $({
        countNum: $this.text()
      }).animate({
        countNum: countTo
      },
        {
          duration: 1000,
          easing: 'linear',
          step: function () {
            let text = commaSeparateNumber(Math.floor(this.countNum));
            text = strSt + text + strEn;
            $this.text(text);
          },
          complete: function () {
            let text = commaSeparateNumber(Math.floor(this.countNum));
            text = strSt + text + strEn;
            $this.text(text);
          }
        });
    });
  }

  componentDidUpdate({ count }) {
    if (count !== this.props.count) {
      $(`.number-${this.prefix}`).each(function () {
        let $this = $(this);
        const countTo = $this.attr('data-count');
        const strSt = $this.attr('data-str-st');
        const strEn = $this.attr('data-str-en');
        if (!countTo || !/^\d+$/.test(countTo)) {
          return;
        }
        $({
          countNum: $this.text()
        }).animate({
          countNum: countTo
        },
          {
            duration: 1000,
            easing: 'linear',
            step: function () {
              let text = commaSeparateNumber(Math.floor(this.countNum));
              text = strSt + text + strEn;
              $this.text(text);
            },
            complete: function () {
              let text = commaSeparateNumber(Math.floor(this.countNum));
              text = strSt + text + strEn;
              $this.text(text);
            }
          });
      });
    }
  }

  render() {
    const { element } = this.props;
    let subTextData = {};
    if (!/^\d+$/.test(this.props.count)) {
      subTextData = getSubTextData(this.props.count);
    }
    switch (`${element}`.toLowerCase()) {
      case 'p':
        return (
          <p
            className={`number number-${this.prefix}`}
            data-count={subTextData.num || this.props.count}
            data-str-en={subTextData.strEn || ''}
            data-str-st={subTextData.strSt || ''}
            {...this.props}
          />
        );
      case 'strong':
        return (
          <strong
            className={`number number-${this.prefix}`}
            data-count={subTextData.num || this.props.count}
            data-str-en={subTextData.strEn || ''}
            data-str-st={subTextData.strSt || ''}
            {...this.props}
          />
        );
      default:
        return (
          <div
            className={`number number-${this.prefix}`}
            data-count={subTextData.num || this.props.count}
            data-str-en={subTextData.strEn || ''}
            data-str-st={subTextData.strSt || ''}
            {...this.props}
          />
        );
    }
  }

}

export default AnimatedCounter;
