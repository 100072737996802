import React from 'react';
import {Link} from "react-router-dom";
import AnimatedCounter from "../reuseableComponents/AnimatedCounter";
import './topInfo.css';

export default ({ summary, pageTitle, pageSubTitle, clusterName }) => {
  const summaryData = summary && summary.map((item) => {
    const dataCount = item.description && item.description.split(',').join('');
    return {dataCount, title: item.title};
  });

  return <section className="banner">
    <div className="wrapper">
      <div className="banner-inner">
        <div className="banner-left">
          <ul className="breadcrumb">
            <li>
              <Link to="/">
                Performance Smart Board
              </Link>
            </li>
            <li>
              {clusterName}
            </li>
          </ul>
          <h3>{pageTitle}</h3>
          <small>{pageSubTitle}</small>
        </div>
        <div className="banner-right">
          <ul className="banner-right-data">
            {
              summaryData && summaryData.map((item) => {
                return isNaN(item.dataCount)
                  ? (<li>
                    <AnimatedCounter element="strong" count={item.dataCount}/>
                    <span>{item.title}</span>
                  </li>)
                  : (<li>
                    <AnimatedCounter element="strong" count={item.dataCount}/>
                    <span>{item.title}</span>
                  </li>)
              })
            }
          </ul>
        </div>
      </div>
    </div>
  </section>
};
