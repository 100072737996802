import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {getClusterIndicatorsDetailById, getMapApi, getMapData} from '../../Actions';
import Header from '../Header'
import TabsOnMap from "../tabsOnMap";
import IndiaMap from '../charts/India/indiaMap'
import MoreServices from './MoreServices';
import TopInfo from './TopInfo';
import ChartTemplate from './chartTemplates';
import InnerPageMap from './InnerPageMap';
import MobileInnerPageMap from '../mobileView/MobileInnerPageMap';

import Footer from '../footer';
import {injectAppConfigs} from "../reuseableComponents/ConfigProvider";

class IndicatorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicatorDetailData: {},
      mapDetailData: []
    };
  }

  componentDidMount() {
    this.getData(this.props.match.params.id);
  }

  getData = (indicatorId) => {
    this.props.getClusterIndicatorsDetailById(indicatorId)
      .then(data => {
        this.setState({
          indicatorDetailData: data
        })
      })
      .catch(err => console.log('getClusterIndicatorsDetailById Err ', err));
    this.props.getMapApi()
      .then(data => {
        let mapUrls = [];
        Object.keys(data).forEach(id => {
          if (id === indicatorId) {
            mapUrls = data[id];
          }
        });
        return mapUrls;
      })
      .then(tempMapUrlData => {
        let getMapsdata = [];
        tempMapUrlData.length && tempMapUrlData.forEach(mapUrlData => {
          getMapsdata.push(this.props.getMapData(mapUrlData.url))
        });
        Promise.all(getMapsdata)
          .then(values => {
            const mapData = [];
            tempMapUrlData.forEach((data, index) => {
              mapData.push({
                mapUrlDetails: data,
                data: values[index]
              })
            });
            this.setState({
              mapDetailData: mapData,
              selectedMapTab: mapData[0]

            });
          })
          .catch(err => console.log('getAllMapsData Err ', err));
      })
      .catch(err => console.log('getMapApi Err ', err))
  };

  componentWillReceiveProps({match}) {
    if (match !== this.props.match) {
      if (this.props.match.params.id !== match.params.id) {
        this.setState({indicatorDetailData: {}});
        this.getData(match.params.id);
      }
    }
  }

  handleTabClick(selectedMapTab) {
    this.setState({
      selectedMapTab
    });
  };

  /**
   *
   */
  setIndicatorDataToDefault = () => {

  };

  render() {
    const {mapDetailData = [], selectedMapTab = {}} = this.state;
    const { appConfigs } = this.props;
    const {data: countriesData = []} = mapDetailData.length && mapDetailData[0].data;
    const {related_data, data, summary, page_title, page_sub_title, cluster_name, imageUrl} = this.state.indicatorDetailData;
    const mapDetails = {};
    selectedMapTab && selectedMapTab.mapUrlDetails && mapDetailData.forEach(map => {
      if (selectedMapTab.mapUrlDetails.title === map.mapUrlDetails.title) {
        mapDetails.data = map.data.data;
        mapDetails.type = map.mapUrlDetails.type;
        mapDetails.title = map.mapUrlDetails.title;
      }
    });

    const InnerPageMapComponent = appConfigs.isDesktop ? InnerPageMap : MobileInnerPageMap;

    const indicatorId = get(this.props, 'match.params.id');
    return (
      <div style={{textAlign: "left"}}>
        <Header/>
        <main>
          <TopInfo
            summary={summary}
            clusterName={cluster_name}
            pageTitle={page_title}
            pageSubTitle={page_sub_title}
          />
          <div
            className={`banner-overlap-contents ${indicatorId === '187' || indicatorId === '89' ? 'ent-page' : ''}`}
          >
            <ChartTemplate indicatorDetailData={this.state.indicatorDetailData} indicatorId={this.props.match.params.id} />
            {mapDetails.type === 'india' && selectedMapTab && countriesData && countriesData.length ? <TabsOnMap
              mapDetailData={this.state.mapDetailData}
              handleTabClick={(item) => this.handleTabClick(item)}
            /> : null}
            {
              countriesData && countriesData.length ? (
                <InnerPageMapComponent indicatorId={this.props.match.params.id} countriesData={countriesData} mapDetails={mapDetails}/>
              ) : null
            }
          </div>
          <MoreServices relatedData={related_data} clusterName={cluster_name}/>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({state}) => ({
  clusters: state.clusters.data,
  clusterIndicators: state.clusterIndicators.data,
  clusterIndicatorsDetail: state.clusterIndicatorsDetail.data
});

const mapDispatchToProps = (dispatch) => ({
  getClusterIndicatorsDetailById: (id) => dispatch(getClusterIndicatorsDetailById(id)),
  getMapApi: () => dispatch(getMapApi()),
  getMapData: (url) => dispatch(getMapData(url))
});

export default injectAppConfigs(connect(mapStateToProps, mapDispatchToProps)(IndicatorDetail));

