import React, {Component} from 'react';
import HighMaps from 'react-highcharts/ReactHighmaps.src';
import $ from 'jquery';
import config from './config';
import Countries from './countries';
import {getTrendsDataForWorldMap} from './trendsData';
import LeftSection from './LeftSection';
import './worldmap.css';

class CustomHighMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configObj: {},
      selectedCountry: '',
      selectedCountryCode: '',
    }
  }

  counter = 0;
  parentUpdated = false;


  getCountryKey = (classArray) => {
    let key = null;
    for (let i = 0; i < classArray.length; i++) {
      if (classArray[i].match(/highcharts-key/g)) {
        let keyParts = classArray[i].split('-');
        for (let j = 2; j < keyParts.length; j++) {
          if (!key) {
            key = keyParts[j];
          } else {
            key += '-' + keyParts[j];
          }

        }
        break;
      }
    }

    return key;
  };

  getCountryName = (classArray) => {
    let key = null;
    for (let i = 0; i < classArray.length; i++) {
      if (classArray[i].match(/highcharts-name/g)) {
        const parts = classArray[i].split('-');
        key = '';
        for (let j = 2; j < parts.length; j++) {
          let nameParts = parts[j].split('');
          nameParts[0] = nameParts[0].toUpperCase();

          key += ' ' + nameParts.join('');
        }
        break;
      }
    }

    return key;
  };

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  beginTimer = (index) => {
    return
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.counter = index;

    this.timer = setInterval(() => {
      const data = this.state.countryToMap;
      if (this.counter == this.props.countriesData.length - 1) this.counter = 0;
      data.forEach((item, index) => {
        if (this.counter === index) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      this.setState({
        configObj: config(data, a => console.log(a), this.counter, {
          restart: this.beginTimer,
          showLabel: this.showLabel,
          stopTimer: this.stopTimer,
        })
      }, () => {
        this.showLabel();
      });
      this.counter++;
    }, 15000)
  };

  showLabel = () => {
    const labelOffsetTop = 40;
    const labelOffsetLeft = 7;

    const classString = $('#world-map-container .highcharts-point-select').attr('class');
    if (classString) {
      const classes = classString.split(' ');
      const countryKey = this.getCountryKey(classes);
      const countryName = this.getCountryName(classes);
      const containerPosition = $('#world-map-container').offset();
      const itemPosition = $('#world-map-container .auto-label-' + countryKey).offset();
      if (countryName && itemPosition) {
        $('#world-map-container #tooltip-world').html(countryName)
          .css({
            'left': itemPosition.left - containerPosition.left + labelOffsetLeft,
            'top': (itemPosition.top - containerPosition.top) - labelOffsetTop
          })
          .show();
        this.parentUpdated = false;
        this.setState({
          selectedCountry: countryName,
          selectedCountryCode: countryKey
        })
      }
    }
  };

  componentDidMount() {
    const countryToMap = [];
    this.props.countriesData.forEach(item => {
      Countries.forEach((mapCountry) => {
        if (mapCountry['hc-key'] === item.country_code) {
          countryToMap.push({...mapCountry, ...{selected: false}})
        } else if (mapCountry['hc-key'] === item.country_code.toLowerCase()) {
          countryToMap.push({...mapCountry, ...{selected: false}})
        }
      });
    });

    countryToMap[1].selected = true;

    this.setState({
      configObj: config(countryToMap, a => console.log(a), this.counter, {
        restart: this.beginTimer,
        showLabel: this.showLabel,
        stopTimer: this.stopTimer,
      }),
      countryToMap
    });
    this.counter++;
    this.showLabel();
    setTimeout(() => {
      this.showLabel();
    }, 0)
    this.beginTimer(this.counter);
  }

  componentWillReceiveProps({optionSelectedCountry}) {
    if (optionSelectedCountry !== this.props.optionSelectedCountry) {
      clearInterval(this.timer);
      const {countryToMap} = this.state;
      const newCountryToMap = countryToMap.map(item => {
        let selected = false;
        if (item['hc-key'] === optionSelectedCountry.toLowerCase()) {
          selected = true;
        }
        return {
          ...item, selected
        };
      });
      this.setState({countryToMap: newCountryToMap});

      this.setState({
        configObj: config(newCountryToMap, a => console.log(a), this.counter, {
          restart: this.beginTimer,
          showLabel: this.showLabel,
          stopTimer: this.stopTimer,
        }),
        countryToMap
      });
      setTimeout(() => {
        this.showLabel()
      }, 0)
    }
  }

  render() {
    let selectedStateDetails = {};
    const {countriesData, indicatorId} = this.props;
    countriesData.forEach(country => {
      if (country.country_name && (country.country_code.trim().toLowerCase() == this.state.selectedCountryCode.trim().toLowerCase())) {
        selectedStateDetails = JSON.parse(JSON.stringify(country));
      }
    });
    let data;
    if (selectedStateDetails && selectedStateDetails.trends && indicatorId) {
      data = getTrendsDataForWorldMap(selectedStateDetails, indicatorId);
      if (!this.parentUpdated) {
        const as_on_date = selectedStateDetails.as_on_date;
        const total_count = selectedStateDetails.total_count;
        this.props.setSelectedStateOrCountry(
          this.state.selectedCountry.trim(),
          total_count,
          as_on_date
          // data && data.totalCount,
          // data && `${data.categories[0]} to ${data.categories[data.categories.length - 1]}`,
        );
        this.parentUpdated = true;
      }
    }
    return (
      <div className='inner-page-world-map' id="world-map-container" style={{position: 'relative'}}>
        <LeftSection selectedStateDetails={selectedStateDetails} data={data} indicatorId={indicatorId} />
        <div id="tooltip-world" style={{
          zIndex: 100,
          position: 'absolute',
          border: '1px solid red',
          display: 'none',
          padding: '10px',
          backgroundColor: 'white'
        }}/>
        <HighMaps ref="map" config={this.state.configObj}/>
      </div>
    )
  }
}

export default CustomHighMap
