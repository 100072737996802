import React, {Component} from 'react';
import './tabsOnMap.css';

const data = [
  {id: '1', text: 'State Wise Passport Issued'},
  {id: '2', text: 'State Wise Passport Seva Kendra'},
  {id: '3', text: 'Test Tab'},
];

export default class TabsOnMap extends Component {
  state = { selected: -1 };

  componentDidMount() {
    const {mapDetailData} = this.props;
    this.setState({
      selected: mapDetailData[0].mapUrlDetails.title,
    })
  }
  componentWillReceiveProps({mapDetailData = []}) {
    if (mapDetailData.length !== 0 && this.props.mapDetailData.length == 0) {
      this.setState({
        selected: mapDetailData,
      })
    }
  }

  onTabClick = (tab) => {
    this.props.handleTabClick(tab);
    this.setState({
      selected: tab.mapUrlDetails && tab.mapUrlDetails.title,
    })
  };

  render() {
    const { mapDetailData = [] } = this.props;
    return <div className="wrapper">
      <ul className='tabList'>
        {
          mapDetailData.map((item) => <li
            key={item.id}
            onClick={() => this.onTabClick(item)}
            className={`tab ${item.mapUrlDetails && this.state.selected === item.mapUrlDetails.title ? 'selected' : ''}`}>
            {item.mapUrlDetails && item.mapUrlDetails.title}
          </li>)
        }
      </ul>
    </div>;
  }
}
