import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux'
import { getHomePageBanner } from '../../Actions'
import RightArrow from '../../assets/right.png';
import LeftArrow from '../../assets/left.png';
import {injectAppConfigs} from "../reuseableComponents/ConfigProvider";
import { Link } from "react-router-dom";

const mobileBanners = ['m_banner1.jpg', 'm_banner2.jpg', 'm_banner3.jpg'];
const desktopBanners = ['banner1.jpg', 'banner2.jpg', 'banner3.jpg'];

class Banner extends Component {
  state = {
    currentIndex: 0
  };

  componentWillMount() {
    this.props.getHomePageBanner();
  }

  goToNextSlide = () => {
    this.refs.slider.slickNext();
  };

  goToPrevSlide = () => {
    this.refs.slider.slickPrev();
  };

  goToSlide = (slideIndex) => () => {
    this.refs.slider.slickGoTo(slideIndex);
  };

  render() {
    const { homePageBanner = [], appConfigs = {} } = this.props;
    const { currentIndex } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 12000,
      pauseOnHover: false,
      dotsClass: 'slick-dots-custom',
      customPaging: () => <button />,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentIndex: newIndex });
      },
    };

    const banners = (appConfigs.isDesktop ? homePageBanner.desktop : homePageBanner.mobile) || [];

    return (
      <div>
        {
          !!(banners && banners.length) && (
            <Slider ref="slider" {...settings}>
              {
                banners.map(data=>(
                  <div key={data.imageUrl}>
                  {(data.url ? 
                    <Link to="/pm-inaugurated">
                      <img src={data.imageUrl} alt={data.imageAlt} />
                    </Link> 
                  :
                    <img src={data.imageUrl} alt={data.imageAlt} />
                  )}
                  </div>
                ))
              }
            </Slider>
          )
        }
        <div className="custom-slick-controls">
          <div onClick={this.goToPrevSlide} className="arrow-left">
            <img src={LeftArrow} />
          </div>
          <ul className="slick-dots-custom">
            {
              banners.map((val, index) => {
                return (
                  <li className={`${index === currentIndex ? 'slick-active' : ''}`} onClick={this.goToSlide(index)}>
                    <button />
                  </li>
                )
              })
            }
          </ul>
          <div onClick={this.goToNextSlide} className="arrow-right">
            <img src={RightArrow} />
          </div>
        </div>
      </div>

    );
  }
}


const mapStateToProps = ({state}) => ({
  homePageBanner: state.homePageBanner.data
})

const mapDispatchToProps = (dispatch) => ({
  getHomePageBanner: () => dispatch(getHomePageBanner())
})

export default injectAppConfigs(connect(mapStateToProps, mapDispatchToProps)(Banner));
