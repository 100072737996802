import React, {Component} from 'react';
import get from 'lodash/get';
import AnimatedCounter from "../../../reuseableComponents/AnimatedCounter/index";
import Chart from "../../../charts/Chart";
import {CHART_TYPES, chartColors, tableColors} from "../../../../constant";
import {formatNumber, grantsAndLoansPie, grantsAndLoansTrends} from "./grantsAndLoansData";
import {areaOptions, disbursedOptions} from "./grantsAndLoansTrendsConfig";
import '../styles.css';

export const Table = ({yearwise, property, colorIndex}) => {
  const colorPoints = tableColors[colorIndex % chartColors.length];
  const color = `rgb(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]})`;
  const background = `rgba(${colorPoints[0]}, ${colorPoints[1]}, ${colorPoints[2]}, .1)`;

  const data = [];
  for (const [year, yearData] of Object.entries(yearwise)) {
    data.push({year, count: yearData[property]});
  }

  return <div className='grants-and-loans-table'>
    <ul>
      {
        data.map(({year, count}) => {
          return <li>
            <tr className="grants-and-loans-table-row" style={{background}}>
              <td className="grants-and-loans-table-year">
                <div style={{ borderColor: color }} className="grants-and-loans-table-row-radio" />
                <div style={{color}}>{year}</div>
              </td>
              <AnimatedCounter element="div" count={`₹${formatNumber(count)}cr`}/>
            </tr>
          </li>
        })
      }
    </ul>
  </div>
};

class GrantsAndLoans extends Component {
  render() {
    const {data} = this.props;
    if (!data || !data.grant) {
      return null;
    }

    const {
      allocatedPieChartData,
      disbursedPieChartData,
      overAllAllocatedTotal,
      overAllDisbursedTotal,
      allocatedCharts,
      disbursedCharts,
      legendColorMap,
    } = grantsAndLoansPie(data.grant.countrywise);
    const {
      allocatedDataTrends,
      disbursedDataTrends,
    } = grantsAndLoansTrends(data.grant);

    return <div className="graph-sec pieChartBoxWrapper">
      <div className="graph-box pieChartBox">
        <div className='headingGrants'>
          <h3>
            <small>{allocatedDataTrends.asOnDate}</small>
            {allocatedPieChartData.title}
          </h3>
          <div className='summaryGrants'>
            <span className='rupeeGrants' style={{color: chartColors[0]}}>&#8377;</span>
            <AnimatedCounter style={{color: chartColors[0]}} element="p" count={overAllAllocatedTotal.toFixed(2)}/>
            <span className='croresGrants' style={{color: chartColors[0]}}>crores</span>
          </div>
        </div>
        <div className='pieChartsWrapper'>
          {/*<Chart*/}
          {/*chartType={CHART_TYPES.AREA}*/}
          {/*data={allocatedDataTrends}*/}
          {/*options={{ ...areaOptions, color: chartColors[0] }}*/}
          {/*/>*/}
          <Table
            yearwise={get(data, 'grant.yearwise', {})}
            property='allocated'
            colorIndex={0}
          />
          {
            allocatedCharts.map((chart, index) =>
              <Chart
                className="pieChartContainer"
                chartType={CHART_TYPES.PIE}
                data={chart}
                key={index}
              />)
          }
        </div>
      </div>
      <div className="graph-box pieChartBox">
        <div className='headingGrants'>
          <h3>
            <small>{disbursedDataTrends.asOnDate}</small>
            {disbursedPieChartData.title}
          </h3>
          <div className='summaryGrants'>
            <span className='rupeeGrants' style={{color: chartColors[1]}}>&#8377;</span>
            <AnimatedCounter style={{color: chartColors[1]}} element="p" count={overAllDisbursedTotal.toFixed(2)}/>
            <span className='croresGrants' style={{color: chartColors[1]}}>crores</span>
          </div>
        </div>
        <div className='pieChartsWrapper right-wrapper'>
          {/*<Chart*/}
          {/*chartType={CHART_TYPES.AREA}*/}
          {/*data={disbursedDataTrends}*/}
          {/*options={disbursedOptions}*/}
          {/*/>*/}
          <Table
            yearwise={get(data, 'grant.yearwise', {})}
            property='disbursed'
            colorIndex={1}
          />

          {
            disbursedCharts.map((chart, index) =>
              <Chart
                className='pieChartContainer'
                chartType={CHART_TYPES.PIE}
                data={chart}
                key={index}
              />)
          }
        </div>
      </div>
      <div className="pie-legend">
        {
          Object.keys(legendColorMap).map((country, index) => {
            return (
              <div className="legend-tile" key={index}>
                <div style={{border: `3px solid ${legendColorMap[country]}`}} className="legend-color"/>
                <div className="legend-name">
                  {country}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>;
  }
}

export default GrantsAndLoans;
