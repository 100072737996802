import React from 'react';
import Indicator from "../indicator/index";
import Slider from "react-slick/lib";
import {injectAppConfigs} from "../reuseableComponents/ConfigProvider";

const MoreServices = ({relatedData, clusterName, appConfigs = {}}) => {
  const indicators = relatedData
    && Object.keys(relatedData).map((item, index) => <div key={index}>
      <Indicator {...relatedData[item]}/>
    </div>);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: (indicators && indicators.length) > 4 ? 4.5 : (
      (indicators && indicators.length) > 1 ? indicators.length : 1
    ),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: (indicators && indicators.length) > 3 ? 3.5 : (
            (indicators && indicators.length) > 1 ? indicators.length : 1
          ),
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: (indicators && indicators.length) > 2 ? 2.5 : (
            (indicators && indicators.length) > 1 ? indicators.length : 1
          ),
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
        }
      }
    ]
  };

  return indicators && indicators.length
    ? <section className="more-services-slider">
      <div className="wrapper">
        <h3>
          More from {clusterName}
        </h3>
        <div className="more-services clearfix">
          {
            appConfigs.isDesktop
              ? <ul className="services">
                {
                  indicators.length > 4 ? (
                    <Slider {...settings}>
                      {indicators}
                    </Slider>
                  ) : indicators
                }
              </ul>
              : <ul className="services">
                <Slider {...settings}>
                  {indicators}
                </Slider>
              </ul>
          }
        </div>
      </div>
    </section>
    : null
};

export default injectAppConfigs(MoreServices);
