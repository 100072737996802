import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from "./CustomSelect";
import AnimatedCounter from "../../reuseableComponents/AnimatedCounter";
import { chartColors, getFormattedNumber, CHART_TYPES } from '../../../constant';
import Chart from "../../charts/Chart";

class SocialMediaFollowers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: '',
      tabs: '',
      selectedTab: {},
      selectedIndex: 0,
      isMenuOpen: false,
    };
  }

  componentDidMount() {
    const { social } = this.props.data;
    var socialIndex = social.findIndex(idx => idx.title === "India")
    if(socialIndex != -1){
      this.setState({
        selectedCountry: social[socialIndex],
      })
    } else{
      this.setState({
        selectedCountry: social[0],
      })
    }
  }

  countryDropdownHandler = (selectedCountry) => {
    this.setState({ selectedCountry: selectedCountry, selectedTab:{}, selectedIndex: 0, isMenuOpen: false });
  };

  openCountryMenu = (e) => {
    this.setState({ isMenuOpen: true })
  };

  closeCountryMenu = () => {
    this.setState({ isMenuOpen: false })
  };

  setSelectedTab = (e, selectedTab, index) => {
    this.setState({selectedTab, selectedIndex: index})
  }

  createChartData = (selectedTabData) => {
    const trends = selectedTabData.map((dataItem, i) => {
      const series = [{
        name: dataItem.trend_title,
        data: []
      }];
      const categories = [];

      dataItem.yearwise_data.forEach((data) => {
        series[0].data.push(isNaN(Number(data.count)) ? 0 : Number(data.count));
        categories.push(data.year);
      });
      const subtitle = dataItem.trend_count ? getFormattedNumber(Number(dataItem.trend_count)) : 0;
      const data = {
        title: dataItem.trend_title,
        series: series,
        subtitle: subtitle,
        categories,
      };
      return(
        {
          color: chartColors[i % 6],
          data,
          timeRange: dataItem.trend_as_on,
          totalCount: isNaN(Number(dataItem.trend_count)) ? 0 : dataItem.trend_count  
        }
      )
    });
    return trends;
  }

  render() {
    const { social } = this.props.data;
    const { trend_data: tabs } = this.state.selectedCountry;
    const { selectedTab, selectedIndex, selectedCountry, isMenuOpen } = this.state;
    const selectedTabData = (selectedTab && selectedTab.post_data) || (tabs && tabs[0] && tabs[0].post_data);
    const chartsData = selectedTabData && selectedTabData.length && this.createChartData(selectedTabData);
    const formatedCountries = social.map((country) => {
      return {
        ...country,
        value: country.country_name,
        label: country.country_name,
      }
    });

    return (
      <section className="destination-outer events-engagement">
        <div className="wrapper">
          <div className="dropdown-tab-wrap clearfix">

            <div className="select-outer">
              <div className="select-inner">
                <div className={`drop-wrap ${isMenuOpen ? 'drop-wrap-open' : ''}`} onClick={this.openCountryMenu}>
                  <span>Select Country</span>
                  <span>{selectedCountry.country_name}</span>
                </div>
                <CustomSelect
                  formatedCountries={formatedCountries}
                  isMenuOpen={isMenuOpen}
                  countryDropdownHandler={this.countryDropdownHandler}
                  selectedCountry={selectedCountry}
                  closeMenu={this.closeCountryMenu}
                />
              </div>
            </div>
            <div className="coordinate-tabs ">
              <div className="wrapper">
                <ul className="tabs">
                  {
                    tabs && tabs.map((tab, index) => {
                      return (<li
                        onClick={(e) => this.setSelectedTab(e, tab, index)}
                        className={ index === selectedIndex ? "active" : ''}>
                        <a href="javascript:void(0)" >{ tab.mission }</a>
                      </li>)
                    })
                  }
                </ul>
              </div>
            </div>
          </div>

          <div className="destination-sec">
            <span className="destination-heading-bg" />
            <h3 className="sec-title">{(selectedTab && selectedTab.post_name) || (tabs && tabs[0] && tabs[0].post_name)}</h3>
            <div className="graph-sec">
              {
                selectedTabData && selectedTabData.length && selectedTabData.map((chart, index) => {
                  const defaultOptions = {color: chartColors[index % 6]};
                  return (
                    <div className="graph-box event-engagement ent-table" style={{ position: 'relative' }}>
                      <div>
                        <h3 style={{maxWidth: 'calc(100% - 80px)', display: 'inline-block', fontSize: '24px'}}>
                          <small>{chart.trend_as_on}</small>
                          {chart.trend_title}
                        </h3>
                        <AnimatedCounter style={{ color: chartColors[index % 6] }} element="p" count={chart.trend_count}/>
                        {
                          chart.trend_icon ? (
                            <div className="trend-image-container">
                              <img src={chart.trend_icon} alt={chart.trend_title} />
                            </div>
                          ) : null
                        }
                      </div>
                      <Chart options={defaultOptions} chartType={CHART_TYPES.AREA} data={chartsData[0].data}/>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

SocialMediaFollowers.propTypes = {
  data: PropTypes.object
};

export default SocialMediaFollowers;
