import React, { Component } from 'react'
import PropTypes from 'prop-types';

class ConfigProvider extends Component {

  static childContextTypes = {
    appConfigs: PropTypes.object,
  };

  getChildContext() {
    const isDesktop = window.screen.width > 991;

    return {
      appConfigs: {
        isDesktop: !!isDesktop,
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default ConfigProvider;

/**
 * injectAppConfigs
 * Higher Order Component which sets appConfigs in the props of component
 *
 * @param WrappedComponent
 * @returns {ConfigProviderHOC}
 */
export const injectAppConfigs = (WrappedComponent) => {
  class ConfigProviderHOC extends Component {

    static contextTypes = {
      appConfigs: PropTypes.object
    };

    render() {
      const appConfigs = this.context.appConfigs;

      return (
        <WrappedComponent {...this.props} appConfigs={appConfigs} />
      );
    }
  }
 return ConfigProviderHOC
};
