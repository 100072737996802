import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import Select from "react-select";

class MyComponent extends Component {
  handleClickOutside = evt => {
    evt.stopPropagation();
    if (this.props.isMenuOpen) {
      this.timer = setTimeout(() => {
        this.props.closeMenu()
        clearTimeout(this.timer)
      }, 100)
    }
  };

  render() {

    const {
      formatedCountries,
      isMenuOpen,
      countryDropdownHandler,
      selectedCountry,
    } = this.props;

    const colourStyles = {
      container: styles => {
        return {
          ...styles
        };
      },
      control: styles => ({
        ...styles,
        display: "none",
        position: "relative",
        bottom: 10,
      }),
      menu: styles => ({
        ...styles,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
        };
      },
    };

    return (
      <div>
        <Select
          defaultValue={formatedCountries[0]}
          label="Single select"
          id="test-id"
          options={formatedCountries}
          styles={colourStyles}
          menuIsOpen={isMenuOpen}
          onChange={e => countryDropdownHandler(e)}
          value={selectedCountry}
        />
      </div>
    )
  }
}

export default onClickOutside(MyComponent);