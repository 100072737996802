import React from 'react';
import Circles from './Circles';
import './wheel.css';
import SvgComponent from './SvgComponent';

export const arrayRotate = (array, reverse) => {
	const arr = [...array];
	if (reverse) {
		arr.unshift(arr.pop());
	} else {
		arr.push(arr.shift());
	}
	return arr;
};

export default class RotationWheel extends React.Component {
	constructor(props) {
		super(props);
		this.onUp = this.onUp.bind(this);
		this.onDown = this.onDown.bind(this);
		this.onSvgClick = this.onSvgClick.bind(this);
		this.rotate = this.rotate.bind(this);
		this.startAutoRotate = this.startAutoRotate.bind(this);
		this.stopAutoRotate = this.stopAutoRotate.bind(this);
		this.getCurrentElement = this.getCurrentElement.bind(this);

		this.state = {
			currentElement: {},
			svgArray: [],
      isAnimating: false,
			positions: [
				{ id: 0, top: '280px', left: '6px', transformAngle: -90 },
				{ id: 1, top: '272px', left: '-30px', transformAngle: -45 },
				{ id: 2, top: '238px', left: '-48px', transformAngle: 0, selected: true },
				{ id: 3, top: '206px', left: '-30px', transformAngle: 45 },
				{ id: 4, top: '200px', left: '4px', transformAngle: 90 },
			]
		};
	}

	componentDidMount() {
		this.startAutoRotate();
		if (this.props.clusters.length) {
			const svgArray = this.props.clusters.map(data => ({ clustersId: data.id, id: `svg${data.id}`, text: data.name }));
			this.setState({ svgArray, currentElement: svgArray[2] });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.clusters.length && this.props.clusters.length === 0) {
			const svgArray = nextProps.clusters.map(data => ({ clustersId: data.id, id: `svg${data.id}`, text: data.name }));
			this.setState({ svgArray, currentElement: svgArray[2] });
		}

    if (nextProps.isRotationPaused !== this.props.isRotationPaused) {
      if (nextProps.isRotationPaused) {
        this.stopAutoRotate();
      } else {
        this.startAutoRotate();
      }
    }
	}

	onUp() {
		this.stopAutoRotate();
		this.rotate(true);
		// this.rotate(true, () => setTimeout(this.startAutoRotate, 5000));
	}

	getCurrentElement(array) {
		const currentPosition = array.findIndex(item => item.selected);
		return this.state.svgArray[currentPosition];
	}

	rotate(reverse = false, callback = null) {
		const positions = this.setTransitions(arrayRotate(this.state.positions, reverse), reverse)
		this.setState({
			positions,
			currentElement: this.getCurrentElement(positions),
      isAnimating: true,
		}, () => {
      /**
       * setTimeouts are added to let the dom render before removing
       * the transition if we remove these the below logic will have
       * no effect due to setState batching.
       */
		  this.outerTimer2 = setTimeout(() => {
        const { positions } = this.state;
        this.setState({
          positions: positions.map((p) => {
            return {
              ...p,
              noTransition: false,
            }
          })
        }, () => {
          this.innerTimer2 = setTimeout(() => {
            const { positions } = this.state;
            const newPositions = this.resetTransitions(positions, reverse);
            this.setState({
              positions: newPositions,
              isAnimating: false,
            });
            clearTimeout(this.innerTimer2);
          }, 100)
        });
        clearTimeout(this.outerTimer2);
      }, 100)
    });
	}

	onDown() {
		this.stopAutoRotate();
		this.rotate();
		// this.rotate(false, () => setTimeout(this.startAutoRotate, 5000))
	}

	startAutoRotate() {
		this.interval = setInterval(this.rotate, 5000);
	}

	stopAutoRotate() {
		clearInterval(this.interval);
	}

	setTransitions(positions, reverse, delta = 1) {
		return positions.map(p => {
			const noTransition = (reverse && p.id > 4 - delta) || (!reverse && p.id < 0 + delta);
			const deltaAngle = reverse ? 30 : -30;
			return {
				...p,
        transformAngle: noTransition ? p.transformAngle + deltaAngle : p.transformAngle,
				noTransition: noTransition ? noTransition : p.noTransition,
			}
		})
	}

  resetTransitions(positions, reverse, delta = 1) {
    return positions.map(p => {
      const noTransition = (reverse && p.id > 4 - delta) || (!reverse && p.id < 0 + delta);
      const deltaAngle = reverse ? -30 : 30;
      return {
        ...p,
        transformAngle: noTransition ? p.transformAngle + deltaAngle : p.transformAngle,
        noTransition: false
      }
    })
  }

	/**
	 * This method sets positions arrays in such a way that selected elements position is set as mid position
	 * Since elements and positions are mapped with indexes in respective arrays
	 * the objective is fix mid position at element's index in the positions array, so elements mapped position is always mid
	 * @param position: selected element's position in positions array
	 */
	onSvgClick = (position, svgId) => () => {
		this.stopAutoRotate();
		let positions = [...this.state.positions];
		const itemIndex = positions.indexOf(position);
		let midPositionIndex = positions.findIndex(p => p.id === 2);
		const reverse = itemIndex > midPositionIndex;
    const { svgArray } = this.state;
    const svgIdArray = svgArray.map(svg => svg.id);
    const index = svgIdArray.indexOf(svgId);
    const elem = {...positions[index]}
    const delta = 2 - elem.id % 2;
    if (elem.id === 2) {
      return;
    }
    const transitionReverse = elem.id > 2;
		while ((positions.findIndex(p => p.id === 2)) !== itemIndex) {
			positions = arrayRotate(positions, transitionReverse);
		}

    positions = [...this.setTransitions(positions, transitionReverse, delta)];
		this.setState({
			positions: positions,
			currentElement: this.getCurrentElement(positions),
      isAnimating: true,
    }, () => {
		  this.outerTimer1 = setTimeout(() => {
		    this.setState({
          positions: positions.map((p) => {
            return {
              ...p,
              noTransition: false,
            }
          }),
        }, () => {
		      this.innerTimer1 = setTimeout(() => {
		        const { positions } = this.state;
		        let newPositions = [...positions];
		          newPositions = this.resetTransitions(newPositions, transitionReverse, delta);
		        this.setState({
              positions: newPositions,
              isAnimating: false,
            });
            clearTimeout(this.innerTimer1);
          }, 100);
        });
		    clearTimeout(this.outerTimer1);
      }, 100)
    });
		// add callback for autorotate , () => setTimeout(this.startAutoRotate, 5000)
	};

	componentWillUpdate(newProps, { currentElement = {} }) {
	  const stateElement = this.state.currentElement || {};
		if (stateElement.id !== (currentElement && currentElement.id)) {
			this.props.getClusterIndicatorsById(currentElement.clustersId);
		}
	}

	componentWillUnmount() {
		this.stopAutoRotate();
	}

	render() {
		const { positions, isAnimating } = this.state;
		let svgArray = [...this.state.svgArray]
		// svgArray = svgArray.slice(0, 1);
		return <div className={`svgWrapper ${isAnimating ? 'no-pointer-events' : ''}`}>
			<Circles/>
			{
				svgArray.map((item, index) =>
					<SvgComponent
						key={item.id}
						id={item.id}
						onSvgClick={this.onSvgClick(positions[index], item.id)}
						svgStyle={{
							position: 'absolute',
							top: positions[index].top,
							left: positions[index].left,
							transform: `rotate(${positions[index].transformAngle}deg) scale(1.7)`,
							transition: positions[index].noTransition ? 'none' : 'transform 0.4s ease-in-out,-webkit-transform 0.4s ease-in-out'
						}}
						text={item.text}
						fill={positions[index].selected ? 'url(#SVGID_1_)' : 'url(#SVGID_2_)'}
						fill1={positions[index].selected ? '#D95D3E' : '#2EAF95'}
						fill2={positions[index].selected ? '#D95D3E' : '#30997E'}
					/>)
			}
			<div className="outer-circle">
				<div className="inner-circle">
					<div className="rotation-arrows">
            <span className="arrow upBtn" onClick={this.onUp}>
              <i className="icon">
                <svg width="12px" height="12px" viewBox="0 0 24 24">
                  <path fillRule="evenodd"  stroke="rgb(37, 53, 103)" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round" fill="none" d="M2.500,12.500 L11.500,1.500 L20.500,12.500 "/>
                </svg>
              </i>
            </span>
            <span className="arrow downBtn" onClick={this.onDown}>
              <i className="icon">
                <svg width="12px" height="12px" viewBox="0 0 24 24">
                  <path fillRule="evenodd"  stroke="rgb(37, 53, 103)" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round" fill="none" d="M4.500,2.500 L13.500,13.500 L22.500,2.500 "/>
                </svg>
              </i>
            </span>
					</div>
				</div>
			</div>
		</div>
	}
};
