import React, {Component} from 'react';
import Cluster from '../cluster';
import ClusterMobile from '../mobileView/ClusterMobile';
import Banner from '../banner';
import Header from '../Header';
import GlobalFootprint from '../globalFootprint';
import MobileViewGlobalFootprint from '../mobileView/MobileGlobalFootprint';
import Footer from '../footer';
import IndiaFootPrint from '../landingPageIndiaMap'
import IndiaFootprintMobile from '../mobileView/IndiaFootprint'
import './animation.css';
import './landingPage.css';

import { injectAppConfigs } from '../reuseableComponents/ConfigProvider';

class Home extends Component {

  scrollDown = () =>{
    const objDiv = document.getElementById("section0");
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c < objDiv.scrollHeight) {
      window.requestAnimationFrame(this.scrollDown);
      window.scrollTo(0, c + (c < 20 ? 200 : c/20));
    }
  }

  render() {
    const { appConfigs } = this.props;
    return (
      <div>
        <Header />
        <main id="fullpage">
          <section id="section0" className="slider active section">
            <Banner />
            {
            appConfigs.isDesktop ? (
            <div className="scroll-down" onClick={() => this.scrollDown()}>
              <svg width="40px" height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               style={{'fill-rule':'evenodd','clip-rule':'evenodd','stroke-linecap':'round','stroke-linejoin':'round','stroke-miterlimit':'1.5'}}
               >
                <path id="wheel" d="M123.359,79.775l0,72.843" style={{'fill':'none','stroke':'#fff','stroke-width':'20px'}}/>
                <path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" style={{'fill':'none','stroke':'#fff','stroke-width':'20px'}}/>
              </svg>
              <p>Scroll down</p>
            </div>
            ) : ''
          }
          </section>
          <section id="section1" className={`section citizen-section clearfix`}>
            {
              appConfigs.isDesktop ? (
                <Cluster />
              ) : <ClusterMobile />
            }
          </section>
          {
            appConfigs.isDesktop ? (
              <GlobalFootprint />
            ) : <MobileViewGlobalFootprint />
          }
          {
            appConfigs.isDesktop ? (
              <IndiaFootPrint />
            ) : <IndiaFootprintMobile />
          }
          <Footer />
        </main>
      </div>
    );
  }
}

export default injectAppConfigs(Home);
