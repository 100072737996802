import React, {Component} from 'react';
import Slider from "react-slick/lib";
import get from 'lodash/get';
import TemplateHeader from "./templateHeader";
import './slider.css';

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

class MapSlider extends Component {
  render() {
    const {selectedCountry = {}, heading, subHeading, countSuffix, tabClass, showPrice, selectedHeaderData} = this.props;
    const count = get(selectedCountry, 'trends.length');
    const suffix = count === 1 ? countSuffix : `${countSuffix}s`;
    const countText = `${count} ${suffix}`;
    const summary = get(selectedHeaderData, 'mapData.summary');

    return (
      <div>{selectedCountry.country_name ?
        <div className={`map-slider-outer ${tabClass ? tabClass : ""}`}>
          <TemplateHeader title={heading} subTitle={subHeading} summary={summary}/>
          <div className="map-slider" style={{display: 'block'}}>
            <Slider {...settings}>
              {
                selectedCountry.trends
                && selectedCountry.trends.map
                && selectedCountry.trends.map(item => {
                  return (
                    <div className="item">
                      <div className={`thumb-img ${get(selectedHeaderData,'link_type')}`}>
                        <img src={item.imageUrl} alt={item.imageAlt}/>
                        {
                          showPrice && (
                            <h4 className="slider-price">
                              {item.price}
                            </h4>
                          )
                        }
                      </div>
                      <div className="text-wrap">
                        {
                          !showPrice && (
                            <h4>
                              {item.title}
                            </h4>
                          )
                        }
                        {showPrice ? <small>{item.title}</small> : null}
                        {
                          !!item.inauguration_date && (
                            <div className="slider-inauguration-date">
                              Inauguration Date - {item.inauguration_date}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </Slider>
          </div>
          <span className="count-project">{countText}</span>
        </div> : null}</div>
    );
  }
}

export default MapSlider;
