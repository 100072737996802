import React from 'react';
import '../../../../Component/globalFootprint/template/peace.css';
import get from "lodash/get";
import TemplateHeader from './templateHeader';

export const FootprintPeaceCard = ({selectedCountry = {}, heading, selectedHeaderData}) => {
  const count = get(selectedHeaderData, 'mapData.total_count');
  const summary = {
    text: 'Deployed Globally',
    count,
  };

  return (
    <div>
      <TemplateHeader title={heading} summary={summary} />
      <div className="footprint-peace-card">
        <div className="head-wrap clearfix">
          <h3>
            <span className="top">India Peace Keeping Forces in</span>
            <span className="bottom">{selectedCountry.country_name}</span>
          </h3>
        </div>
        <div className="card-content">
          <div className="data-box">
            <span>{get(selectedCountry, 'trends[0].Peace_keeping_count', null)}</span>
            <small>{get(selectedCountry, 'trends[0].title', null)}</small>
          </div>
          <div className="img-wrap">
            <img src='./images/peace-bird.png' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootprintPeaceCard;
