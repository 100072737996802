import React, {Component} from 'react';
import Indicator from '../../indicator'
import {getClusters, getClusterIndicatorsById} from '../../../Actions'
import {connect} from 'react-redux'
import Slider from "react-slick/lib";

class Cluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRotationPaused: false,
    };
  }

  componentDidMount() {
    this.props.getClusters();
  }

  componentWillReceiveProps(nextProps) {
    const { clusters, getClusterIndicatorsById } = this.props;
    if (
      (nextProps.clusters && nextProps.clusters.length)
      && !(clusters && clusters.length)
    ) {
      getClusterIndicatorsById(nextProps.clusters[0].id)
    }
  }

  handleClusterSelect = (e) => {
    const { getClusterIndicatorsById } = this.props;
    const selectedClusterId = e.target.value;
    getClusterIndicatorsById(selectedClusterId);
  };

  render() {
    const {getClusterIndicatorsById, clusters = [], clusterIndicators = {}} = this.props;
    const { isRotationPaused } = this.state;
    const indicators = (
      clusterIndicators.data
      && clusterIndicators.data.map
      && clusterIndicators.data.map(item => {
        return (
          <Indicator
            {...item}
          />
        )
      })
    );

    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: (indicators && indicators.length) > 3 ? 3.5 : (
        (indicators && indicators.length) > 1 ? indicators.length : 1
      ),
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: (indicators && indicators.length) > 2 ? 2.5 : (
              (indicators && indicators.length) > 1 ? indicators.length : 1
            )
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
          }
        }
      ]
    };

    return (
      <div style={{textAlign:'left'}}>
        <section className="citizen-section wheel-content-container">
          <div>
            <div className="select-box">
              <select onChange={this.handleClusterSelect}>
                {
                  clusters.map((cluster) => (
                    <option
                      key={cluster.id}
                      value={cluster.id}
                    >
                      {cluster.name}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className={`animated citizen-right ${this.props.activeFold === true ? `fadeInUp` : ``}`}>
              <div className="head">
                <h3>{clusterIndicators.clusterName}</h3>
                <p>
                  {clusterIndicators.clusterDescription}
                </p>
              </div>
              <ul className="services" style={{ display: 'block' }}>
                <Slider {...settings}>
                  {indicators}
                </Slider>
              </ul>
            </div>
          </div>
        </section>


      </div>
    );
  }
}

const mapStateToProps = ({state}) => ({
  clusters: state.clusters.data,
  clusterIndicators: state.clusterIndicators.data,
})

const mapDispatchToProps = (dispatch) => ({
  getClusters: () => dispatch(getClusters()),
  getClusterIndicatorsById: (id) => dispatch(getClusterIndicatorsById(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
