import React, {Component} from 'react';
import Header from '../../Header';
import Footer from '../../footer';
import {getInaugurated, getInauguratedCountry, getCountByCountry} from '../../../Actions'
import {connect} from 'react-redux'
import Modal from 'react-modal';
import Slider from "react-slick";
import './inaugurated.css';
import {injectAppConfigs} from '../../reuseableComponents/ConfigProvider';
import stripHtml from "string-strip-html";
import {Link} from "react-router-dom";
import CustomSelect from "../../indicatorDetail/chartTemplates/CustomSelect";
import {get} from 'lodash';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

class Inaugurated extends Component {

  constructor() {
    super();
 
    this.state = {
      modalIsOpen: false,
      activeCard:0,
      visible: 12,
      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0,
      isMenuOpen: false,
      selectedCountry: '',
      countryName: 'allcountries'
    };
  }

  componentDidMount() {
    const {countryName} = this.state;
    this.props.getInaugurated();
    this.props.getCountByCountry();
    this.props.getInauguratedCountry(countryName);
  }

  componentDidUpdate(prevProps, prevState) {
    const {countryName} = this.state;
    const{getInauguratedCountry} = this.props;
    if (prevState.countryName !== countryName) {
      getInauguratedCountry(countryName.toLowerCase());
    }
  }
 
  openModal = (event, index) => {
    event.preventDefault();
    this.setState({modalIsOpen: true,  activeCard: index, activeSlide: 0 });
  }
  
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  loadMore = () => {
    this.setState((prev) => {
      return {visible: prev.visible + 12};
    });
  }

  countryDropdownHandler = (selectedCountry) => {
    this.setState({ 
      selectedCountry: selectedCountry,
      countryName: selectedCountry.name,
      isMenuOpen: false 
    });
  };

  openCountryMenu = (e) => {
    this.setState({ isMenuOpen: true })
  };

  closeCountryMenu = () => {
    this.setState({ isMenuOpen: false })
  };

  render() {
    const {inaugurated = {}, inauguratedCountry = {}, countByCountry = {}} = this.props;
    const {activeCard, modalIsOpen, visible, isMenuOpen, selectedCountry} = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      dotsClass: 'slick-dots-custom',
      // customPaging: () => <button />,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })
    };
    const countries = get(countByCountry,"countries",[]).map((country) => {
      return {
        ...country,
        value: country.country,
        label: country.country,
        name: country.value,
      }
    });

    return (
      <div className="pm-inaugurated">
        <Header/>
        <main id="fullpage">
          <div className="inaugurated-page">
            {
              <InauguratedBanner banner={get(inauguratedCountry,"banner",[])}/>
            }
            <div className="pm-inaugrated-outer wrapper">
              <div className="wrapper">
                <section className="pm-launch-heading">
                  <p className="head1">Major Projects Inaugurated / Launched by</p>
                  <p className="head2">Hon’ble PM <strong>Shri Narendra Modi</strong></p>

                  <div className="dropdown-tab-wrap clearfix">
                    <div className="select-outer">
                      <div className="select-inner">
                        <div className={`drop-wrap ${isMenuOpen ? 'drop-wrap-open' : ''}`} 
                          onClick={this.openCountryMenu}
                        >
                          <span>Filter By Country</span>
                          <span>
                            {selectedCountry.country && selectedCountry.country != 'Allcountries' ?
                              selectedCountry.country : 'All Country'
                            } {" "}
                            <small>[{get(inauguratedCountry,"projects", []).length} { " "}
                              {get(inauguratedCountry,"projects", []).length <=1 ? 'Project' : 'Projects'}]
                            </small>
                          </span>
                        </div>
                        <CustomSelect
                          formatedCountries={countries}
                          isMenuOpen={isMenuOpen}
                          countryDropdownHandler={this.countryDropdownHandler}
                          selectedCountry={selectedCountry}
                          closeMenu={this.closeCountryMenu}
                        />
                      </div>
                    </div>
                    <div className="modiji-image">
                      <img src={get(inaugurated, "banner.bannerImage")} 
                        alt={get(inaugurated, "banner.bannerAlt")} 
                      />
                    </div>
                  </div>
                </section>
                <div className="inaugurated-card-container">
                  {get(inauguratedCountry,"projects",[]).slice(0, visible).map((card, index) =>
                    <div key={card.nid} className="inaugurated-card" onClick={(event) => this.openModal(event,index)}>
                      <div className="inaugurated-img">
                        <img src={card.images.length && card.images[0].url } alt={card.images.length && card.images[0].alt}/>
                        <span className="country">
                          {card.country}
                        </span>
                        {
                          card.images.length > 1 ?
                          <i className="count-img">
                            <svg width="35" height="34">
                              <path fill="#FFF" fillRule="evenodd" d="M31.069 34H7.644a3.93 3.93 0 01-3.825-2.999C1.703 30.942 0 29.219 0 27.108V5.854C0 2.626 2.65 0 5.907 0h21.448a3.93 3.93 0 013.826 2.999C33.297 3.058 35 4.781 35 6.892v23.212C35 32.252 33.236 34 31.069 34zm2.564-27.108c0-1.328-1.034-2.421-2.346-2.531l-1.367-.01H17.337a.68.68 0 01-.683-.677.68.68 0 01.683-.678h12.416a2.567 2.567 0 00-2.398-1.641H5.907c-2.503 0-4.54 2.018-4.54 4.499v21.254c0 1.328 1.034 2.421 2.346 2.531V6.892c0-2.148 1.764-3.896 3.931-3.896h4.225a.68.68 0 01.683.678.68.68 0 01-.683.677H7.644c-1.414 0-2.564 1.14-2.564 2.541v16.952l6.665-11.199a2.634 2.634 0 012.333-1.294 2.629 2.629 0 012.287 1.372l2.172 3.953 1.204 2.192 2.157 3.924c.21.383.578.649 1.01.731a1.47 1.47 0 001.211-.308l3.074-2.527a2.687 2.687 0 012.727-.398l1.367 1.032 2.346 2.131V6.892zm0 18.399l-2.346-2.131-1.367-1.242-.161-.146a1.301 1.301 0 00-1.693-.041l-3.074 2.527a2.838 2.838 0 01-1.935.642l1.593 2.899a.675.675 0 01-.6 1.001.684.684 0 01-.601-.353l-4.908-8.931-3.377-6.145a1.275 1.275 0 00-1.109-.666 1.282 1.282 0 00-1.132.628L5.08 26.511v3.593c0 .317.06.62.167.9a2.566 2.566 0 002.397 1.641h23.425c1.413 0 2.564-1.14 2.564-2.541v-4.813zm-7.641 5.448a.597.597 0 01-.063.117.6.6 0 01-.188.187.644.644 0 01-.246.101.67.67 0 01-.513-.101.686.686 0 01-.104-.084.656.656 0 01-.148-.22.681.681 0 01-.039-.391.681.681 0 01.931-.493.644.644 0 01.223.147.633.633 0 01.147.22.684.684 0 01.053.259.656.656 0 01-.053.258zm-.024-15.22c-1.942 0-3.523-1.566-3.523-3.491s1.581-3.491 3.523-3.491c1.943 0 3.523 1.566 3.523 3.491s-1.58 3.491-3.523 3.491zm0-5.627a2.149 2.149 0 00-2.156 2.136c0 1.178.968 2.136 2.156 2.136a2.148 2.148 0 002.156-2.136 2.148 2.148 0 00-2.156-2.136zM15.234 3.933a.697.697 0 01-.252.304.697.697 0 01-.641.062.687.687 0 01-.408-.493.658.658 0 01.101-.508.71.71 0 01.19-.188.687.687 0 011.049.431.66.66 0 01-.039.392z"/>
                            </svg>
                            <span>{card.images.length}</span>
                          </i>
                          : null
                        }
                      </div>
                      <h3 className="card-title">
                        {card.title && (card.title.length > 57 ? card.title.substr(0, 57).concat('...') : card.title )}
                      </h3>
                      <div className="published-date">
                        {card.publishedDate}
                      </div>
                      <div className="amount">
                        {card.price}
                      </div>
                    </div>
                  )}

                  {get(inauguratedCountry,"projects",[]) &&
                    visible < get(inauguratedCountry,"projects",[]).length &&
                      <div className="center-content">
                        <button type="button" className="load-more-btn" onClick={this.loadMore}>
                          Load More
                        </button>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer/>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              overlay: {
                backgroundColor: 'rgba(4, 6, 35, .88)',
              },
              content: {
                border: '0',
                padding: '0',
                top: '50%',
                left: '50%',
                overflow: 'unset',
                borderRadius: '20px',
                 // maxWidth: '1000px',
                width: '95%',
                height: '95%',
                transform: 'translate(-50%, -50%)',
              }
            }}
          >


        {
          inauguratedCountry.projects && inauguratedCountry.projects[activeCard] &&
          <div className="inaugurated-card-modal ">
            <i className="close-icon" onClick={this.closeModal}>

            </i>
            <div  className="inaugurated-img">
              <span className='count-slide'> {(inauguratedCountry.projects[activeCard].images.length ? this.state.activeSlide + 1 : 0)} / {inauguratedCountry.projects[activeCard].images.length}</span>
              <Slider ref="slider" {...settings}>
                { inauguratedCountry.projects[activeCard].images.length &&
                inauguratedCountry.projects[activeCard].images.map((i, index) =>

                  <img  key={index} src={i.url} alt={i.alt}/>

                )
                }
              </Slider>
            </div>
            <div className='content-modal'>
              <div className="published-date">
                {inauguratedCountry.projects[activeCard].publishedDate}
              </div>
          <h3 className="card-title">
            {inauguratedCountry.projects[activeCard].title}
          </h3>
              <span className="amount">
                {inauguratedCountry.projects[activeCard].price}
              </span>
          <div className="description" dangerouslySetInnerHTML={{__html: inauguratedCountry.projects[activeCard].description}}>
          </div>
            </div>
        </div>
        }
        </Modal>


        </main>
      </div>
    );
  }
}

const InauguratedBanner = ({banner}) => {
  return (
    <div className="inaugurated-banner banner">
      <div className="wrapper">
        <div className="banner-inner">
        <div className="banner-left">
          <ul className="breadcrumb">
            <li>
              <Link to="/">
                Smart Board
              </Link>
            </li>
            <li>
              {banner.bannerTitle}
            </li>
          </ul>
          <h3>{banner.bannerTitle}</h3>
        </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({state}) => ({
  inaugurated: state.inaugurated.data,
  inauguratedCountry: state.inauguratedCountry.data,
  countByCountry: state.countByCountry.data
})

const mapDispatchToProps = (dispatch) => ({
  getInaugurated: () => dispatch(getInaugurated()),
  getInauguratedCountry: (country) => dispatch(getInauguratedCountry(country)),
  getCountByCountry: () => dispatch(getCountByCountry())
})

export default connect(mapStateToProps, mapDispatchToProps)(injectAppConfigs(Inaugurated));

// export default injectAppConfigs(Inaugurated);
